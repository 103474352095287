import { config, Config } from '../config';

const currentOrigin = () => window.location.origin;

let configCache:Config;
const getConfig = () => {
  if (typeof configCache !== 'undefined') return configCache;
  const origin = currentOrigin();

  let conf = getConfigByOrigin(config, origin);

  if (!conf) {
    conf = config.local;
  }

  configCache = conf;

  return conf;
};

export const getStageByOrigin = () => {
  const origin = currentOrigin();
  
  const stages = Object.entries(config).filter(([_, entry]) => {
    return entry.urls.some((url) => {
      return origin.includes(url); 
    });
  }).map(([stage, _]) => stage)
  
  return stages.length > 0 ? stages[0] : null;
}

export const getConfigByOrigin = (config: {[key: string]: Config}, origin: string) => {
  const configEntries = Object.values(config).filter( (entry) => {
    return entry.urls.some((url) => {
      return origin.includes(url); 
    });
  });

  return configEntries.length > 0 ? configEntries[0] : null;
}

// Note: This is only used when retrieving local dummy API files
export const getSiteUrl = () => {
  const urls = getConfig().urls;
  const matched = urls.reduce((matchedUrl: string|null, url: string) => {
    if (matchedUrl) return matchedUrl;
    return currentOrigin().includes(url) || url.includes(currentOrigin()) ? url : null;
  }, null);
  return matched;
}

export const getApiUrl = ()=>getConfig().api;

export const getSmithyApiUrl = ()=>getConfig().smithyApi;

export const getAnalyticsConfig = ()=>getConfig().analytics;

export const getAuthConfig = ()=>getConfig().auth;
