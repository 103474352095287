import React from "react";
import CollectionPreferences from "@amzn/awsui-components-react/polaris/collection-preferences";
import { DEFAULT_EVENTS_PAGE_SIZE } from "../api/events";
import { useSearchParams } from "react-router-dom";
import { getIntFromUrlParams, urlUpdater } from "../utils/url";
import { RadioGroup } from "@amzn/awsui-components-react";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import { useTranslation } from "react-i18next";
import { publishMetricsWithAttributes } from "./analytics";
import { publishKatalMetric } from "./katalAnalytics";

export function EventPreferences({ resetEvents }: { resetEvents: () => void }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const updateUrlValue = urlUpdater(setSearchParams);

  return (
    <CollectionPreferences
      onConfirm={({ detail }) => {
        const pageSize = detail.pageSize || DEFAULT_EVENTS_PAGE_SIZE;
        resetEvents();
        publishMetricsWithAttributes("paginationSelection", "pageSize", pageSize.toString());
        publishMetricsWithAttributes("paginationSelection", "pagination", detail.custom.pagination);
        publishKatalMetric("paginationSelectionSize", pageSize.toString());
        publishKatalMetric("paginationSelection", detail.custom.pagination);
        updateUrlValue(["pageSize", pageSize.toString()], ["pagination", detail.custom.pagination]);
      }}
      preferences={{
        pageSize: getIntFromUrlParams(searchParams, "pageSize", DEFAULT_EVENTS_PAGE_SIZE),
        custom: {
          pagination: searchParams.get("pagination") || "load"
        }
      }}
      pageSizePreference={{
        title: t("eventPreferences.pageSizeTitle"),
        options: [
          { value: 10, label: t("eventPreferences.eventCount", { count: 10 }) },
          { value: 25, label: t("eventPreferences.eventCount", { count: 25 }) },
          { value: 50, label: t("eventPreferences.eventCount", { count: 50 }) },
          { value: 100, label: t("eventPreferences.eventCount", { count: 100 }) }
        ]
      }}
      customPreference={(customValue, setCustomValue) => (
        <FormField label={t("eventPreferences.paginationType")} >
          <RadioGroup
            value={customValue.pagination}
            onChange={({ detail }) => setCustomValue({ pagination: detail.value })}
            items={[
              { value: "pages", label: t("eventPreferences.pagination.pages") },
              { value: "load", label: t("eventPreferences.pagination.load") },
              { value: "infinite", label: t("eventPreferences.pagination.infinite") }
            ]}
          />
        </FormField>)}
      confirmLabel={t("eventPreferences.confirm")}
      cancelLabel={t("eventPreferences.cancel")}
      title={t("eventPreferences.title")}
    />
  );
}