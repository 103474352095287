import { useState, useEffect } from 'react';
import { Auth } from "aws-amplify";
import { CognitoIdToken } from "amazon-cognito-identity-js";

export const AUTH_RELOAD_TIMESTAMP_KEY = "authenticationReloaded";
export const ALIAS_KEY = "amzn-rv-userId";
export const FIVE_MIN_MILLS = 1000 * 60 * 5;
export const FIVE_SECS_MILLS = 1000 * 5;

interface SessionInfo {
  idToken?: CognitoIdToken;
  error?: string;
}

//DO NOT USE SESSION STORAGE. 
//SEE: https://issues.amazon.com/issues/cce-3593?selectedConversation=f7b2d28f-f302-4852-97f6-5c51661828ac
const cacheAuthInfo = (alias: string) => {
  if (alias) {
    localStorage.setItem(ALIAS_KEY, alias);
  }
}

const getSessionOrRedirectToAuth = async (): Promise<SessionInfo> => {
  try {
    const session = await Auth.currentSession();
    return { idToken: session.getIdToken() };
  } catch {
    // Prevent infinite reload loop now that users can manually click the sign in button
    const lastSignInAttempt = localStorage.getItem(AUTH_RELOAD_TIMESTAMP_KEY);
    const fiveMinutesAgo = Date.now() - FIVE_MIN_MILLS;
    const attemptSignIn = lastSignInAttempt != null ? new Date(parseInt(lastSignInAttempt)).getTime() < fiveMinutesAgo : true;
    if (attemptSignIn) {
      // TODO: re-enable this call as part of https://issues.amazon.com/issues/cce-3786
      // localStorage.setItem(AUTH_RELOAD_TIMESTAMP_KEY, `${Date.now()}`);
      try {
        await Auth.federatedSignIn({ customProvider: 'AmazonFederate' });
      } catch (err) {
        // report the error to let user instance to handle the error appropriately.
        return { error: `${err}` }
      }
    }
    return {}
  }
};

export function useUserInfoOrRedirectToAuth() {
  const [userAlias, setUserAlias] = useState<string | undefined>();
  const [employeeId, setEmployeeId] = useState<string>('');
  const [permissions, setPermissions] = useState<string[]>([]);
  const [error, setError] = useState<string>();

  useEffect(() => {
    const initializeAuth = async () => {
      const session = await getSessionOrRedirectToAuth();
      if (session.error) {
        setError(session.error);
      } else {
        const userId = session.idToken?.payload['identities'][0].userId;
        const permissions = session.idToken?.payload['permissions'];
        cacheAuthInfo(userId);
        setUserAlias(userId);
        setEmployeeId(session.idToken?.payload['custom:employee_id']);
        setPermissions(permissions ? permissions.split(",") : []);
      }
    };
    initializeAuth();
  }, []);

  return { userAlias, employeeId, permissions, error };
}

export const getAuthTokenOrRedirectToAuth = async (): Promise<string> => {
  try {
    return (await getSessionOrRedirectToAuth()).idToken?.getJwtToken() || "";
  } catch {
    return "";
  }
};

export const getAuthAlias = () => {
  return localStorage.getItem(ALIAS_KEY);
}
