import React from 'react';

export function AmazonLogo() {
  return (
	<svg role="img" aria-label="Amazon" width="116" height="35" viewBox="0 0 116 35" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_3595_18027)">
			<path fillRule="evenodd" clipRule="evenodd" d="M72.0698 27.3728C65.3218 32.3522 55.5353 35.0006 47.1093 35.0006C35.3003 35.0006 24.6657 30.6334 16.6162 23.3684C15.9858 22.797 16.5481 22.017 17.3055 22.4614C25.9899 27.5133 36.7242 30.5563 47.8123 30.5563C55.295 30.5563 63.5169 29.0053 71.0812 25.7946C72.224 25.3093 73.1764 26.5474 72.0608 27.3728" fill="#FF9900"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M74.877 24.1658C74.0153 23.0593 69.172 23.6443 66.9952 23.9028C66.3376 23.9799 66.2333 23.4039 66.8274 22.9867C70.6912 20.2748 77.022 21.0548 77.7567 21.9663C78.4959 22.8824 77.5617 29.2314 73.9428 32.2607C73.385 32.7278 72.8544 32.4784 73.1038 31.8616C73.9201 29.8254 75.7432 25.2723 74.877 24.1658Z" fill="#FF9900"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M67.1494 3.81806V1.18324C67.1494 0.77963 67.4532 0.516602 67.816 0.516602H79.6342C80.0106 0.516602 80.3144 0.7887 80.3144 1.17871V3.44166C80.3144 3.8226 79.9925 4.31691 79.4256 5.10146L73.3034 13.8404C75.5754 13.7859 77.9789 14.1261 80.0423 15.287C80.5094 15.55 80.6319 15.9355 80.6682 16.3165V19.1281C80.6682 19.5136 80.2419 19.9626 79.7975 19.7313C76.1604 17.8266 71.3352 17.618 67.3127 19.754C66.9 19.9716 66.4737 19.5318 66.4737 19.1417V16.4661C66.4737 16.0398 66.4828 15.3052 66.9136 14.6521L74.0063 4.47564H67.8297C67.4533 4.47564 67.1494 4.20354 67.1449 3.80899" fill="white"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M24.0399 20.289H20.4437C20.1036 20.2663 19.8269 20.0079 19.7997 19.6813V1.22852C19.7997 0.856656 20.1126 0.566418 20.4981 0.566418H23.8449C24.1941 0.584558 24.4753 0.847586 24.4979 1.18317V3.59125H24.566C25.4367 1.26027 27.0829 0.171875 29.2959 0.171875C31.509 0.171875 32.9557 1.26027 33.9624 3.59125C34.8377 1.26027 36.8149 0.171875 38.9282 0.171875C40.4384 0.171875 42.0846 0.793167 43.0913 2.18994C44.2342 3.74544 43.9983 5.99478 43.9983 7.97657V19.6224C43.9983 19.9897 43.6854 20.2845 43.3 20.2845H39.7083C39.3455 20.2618 39.0643 19.9761 39.0643 19.6224V9.84044C39.0643 9.06496 39.1278 7.12399 38.96 6.38479C38.6924 5.14221 37.8852 4.79301 36.8467 4.79301C35.976 4.79301 35.069 5.37803 34.6971 6.3077C34.3298 7.2419 34.3615 8.79286 34.3615 9.84044V19.6224C34.3615 19.9897 34.0531 20.2845 33.6677 20.2845H30.076C29.7132 20.2618 29.432 19.9761 29.432 19.6224V9.84044C29.432 7.78156 29.7631 4.75673 27.2144 4.75673C24.6657 4.75673 24.7292 7.70447 24.7292 9.84044V19.6224C24.7292 19.9897 24.4163 20.2845 24.0354 20.2845" fill="white"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M90.4909 0.171875C95.8285 0.171875 98.7128 4.75674 98.7128 10.5796C98.7128 16.4026 95.5247 20.6745 90.4909 20.6745C85.4571 20.6745 82.4005 16.0896 82.4005 10.3846C82.4005 4.67964 85.2893 0.171875 90.4909 0.171875ZM90.5226 3.94044C87.8697 3.94044 87.7064 7.55028 87.7064 9.80416C87.7064 12.058 87.6747 16.8697 90.4909 16.8697C93.3071 16.8697 93.4114 12.9877 93.4114 10.6205C93.4114 9.0695 93.3434 7.20109 92.8763 5.72722C92.4727 4.44382 91.6654 3.94044 90.5272 3.94044" fill="white"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M105.633 20.2884H102.051C101.692 20.2657 101.407 19.98 101.407 19.6263V1.16441C101.429 0.824283 101.729 0.561254 102.091 0.561254H105.425C105.738 0.579394 105.996 0.792538 106.064 1.07824V3.90353H106.132C107.139 1.37755 108.549 0.175781 111.03 0.175781C112.64 0.175781 114.218 0.756258 115.225 2.35257C116.163 3.82644 116.163 6.3116 116.163 8.09838V19.7125C116.123 20.039 115.828 20.2929 115.474 20.2929H111.869C111.538 20.2703 111.266 20.0254 111.229 19.7125V9.69469C111.229 7.67663 111.465 4.72436 108.98 4.72436C108.109 4.72436 107.302 5.30937 106.898 6.19823C106.395 7.3229 106.327 8.45211 106.327 9.69469V19.6308C106.323 19.9982 106.01 20.2929 105.629 20.2929" fill="white"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M57.7257 11.4773C57.7257 12.8786 57.7575 14.0441 57.05 15.2912C56.4786 16.3025 55.5716 16.9238 54.5603 16.9238C53.1817 16.9238 52.3744 15.8717 52.3744 14.3162C52.3744 11.2551 55.1226 10.6973 57.7212 10.6973V11.4773H57.7257ZM61.3492 20.2434C61.1133 20.4566 60.7687 20.4702 60.5011 20.3251C59.3084 19.3319 59.0953 18.8739 58.4422 17.9306C56.4695 19.9396 55.0728 20.5427 52.515 20.5427C49.4902 20.5427 47.132 18.6743 47.132 14.9375C47.132 12.017 48.7147 10.0352 50.9686 9.06018C52.9186 8.20307 55.6442 8.04889 57.7303 7.81307V7.34596C57.7303 6.48885 57.7983 5.47755 57.2904 4.73835C56.855 4.07625 56.0115 3.80415 55.2723 3.80415C53.9027 3.80415 52.6828 4.50707 52.3835 5.9628C52.32 6.28478 52.0842 6.60676 51.7577 6.6249L48.2748 6.2485C47.9801 6.18048 47.6535 5.94466 47.7397 5.49569C48.5424 1.2691 52.3608 -0.00976562 55.7802 -0.00976562C57.5307 -0.00976562 59.8163 0.457337 61.195 1.78155C62.9455 3.41414 62.7777 5.59546 62.7777 7.97179V13.5725C62.7777 15.2595 63.4761 15.9987 64.1336 16.9057C64.3649 17.2322 64.4148 17.6222 64.12 17.8626C63.3854 18.4793 62.0793 19.6131 61.3628 20.2525L61.3537 20.2434H61.3492Z" fill="white"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M10.5937 11.4777C10.5937 12.879 10.63 14.0445 9.92252 15.2916C9.35112 16.3029 8.44412 16.9242 7.43282 16.9242C6.05419 16.9242 5.24697 15.8721 5.24697 14.3166C5.24697 11.2555 7.99516 10.6977 10.5937 10.6977V11.4777ZM14.2171 20.2438C13.9813 20.4569 13.6367 20.4705 13.3691 20.3254C12.1719 19.3323 11.9587 18.8742 11.3102 17.9309C9.33751 19.9399 7.94074 20.5431 5.38302 20.5431C2.35365 20.5431 0 18.6747 0 14.9379C0 12.0173 1.57817 10.0356 3.83205 9.06053C5.78209 8.20342 8.50761 8.04923 10.5937 7.81341V7.34631C10.5937 6.4892 10.6617 5.4779 10.1583 4.7387C9.72299 4.07659 8.87948 3.8045 8.14028 3.8045C6.77072 3.8045 5.55081 4.50742 5.2515 5.96314C5.18801 6.28513 4.95219 6.60711 4.62567 6.62525L1.14281 6.24885C0.84804 6.18082 0.521522 5.945 0.607687 5.49604C1.40584 1.27398 5.21976 -0.00488281 8.64366 -0.00488281C10.3942 -0.00488281 12.6798 0.46222 14.0584 1.78643C15.8089 3.41902 15.6411 5.60035 15.6411 7.97667V13.5774C15.6411 15.2644 16.3395 16.0036 16.9971 16.9106C17.2284 17.2371 17.2783 17.6271 16.988 17.8675C16.2533 18.4842 14.9473 19.618 14.2308 20.2574L14.2217 20.2483L14.2171 20.2438Z" fill="white"/>
		</g>
		<defs>
			<clipPath id="clip0_3595_18027">
				<rect width="116" height="35" fill="white"/>
			</clipPath>
		</defs>
	</svg>
);
}
