// Components
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import BreadcrumbGroup from "@amzn/awsui-components-react/polaris/breadcrumb-group";
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import Header from "@amzn/awsui-components-react/polaris/header";
import Link from "@amzn/awsui-components-react/polaris/link";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Tabs from "@amzn/awsui-components-react/polaris/tabs";
import { SignupList } from './signupsList';
// Hooks
import { useTranslation } from "react-i18next";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useFeatures, featureIsEnabled } from "../../hooks/features";
// Utils
import { externalLinks } from "../../data/externalLinks";

export function ActivityPage() {
  const { t } = useTranslation(["activity", "translation"]);
  const features = useFeatures();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  return <AppLayout
    footerSelector=".footer"
    navigationHide={true}
    toolsHide={true}
    breadcrumbs={<div className="breadcrumbs"><BreadcrumbGroup
      items={[
        { text: t('breadcrumbs.home', { ns: 'translation' }), href: '/' },
        { text: t('breadcrumbs.activity'), href: '#' }]
      }
      onFollow={(event) => {
        navigate(event.detail.href);
        event.preventDefault();
      }}/></div>}
    content={ featureIsEnabled("MyActivity", features, searchParams) ? 
      <div className="page-content">
      <ContentLayout
        header={
        <div className="page-content-header"><Header variant="h1">{t("pageTitle")}</Header></div>}>
        <SpaceBetween direction="vertical" size="xxl">
          <Header 
            actions={<Link
              external data-external-analytics-on="click"
              data-external-analytics-name="myActivity_reportTime"
              data-aci-analytics-name="myActivity_reportTime"
              href={externalLinks.support.trackTime}>
              {t("trackTime")}
            </Link>}>
            {t("volunteerActivity")}
          </Header>
          <Tabs 
            tabs={[
              {
                label: t("upcoming"),
                id: "upcoming",
                content: <SignupList startDate={new Date(Date.now())} sort="oldest-first"/>
              },
              {
                label: t("past"),
                id: "past",
                content: <SignupList endDate={new Date(Date.now())} sort="newest-first"/>
              }
            ]} />
        </SpaceBetween>
      </ContentLayout> </div>:
      t("activityDisabled")
    }
    contentType='cards' />
}
