// Components
import Box from "@amzn/awsui-components-react/polaris/box";
import Button from "@amzn/awsui-components-react/polaris/button";
import Form from "@amzn/awsui-components-react/polaris/form";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Input from "@amzn/awsui-components-react/polaris/input";
import Modal, { ModalProps } from "@amzn/awsui-components-react/polaris/modal";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
// Hooks
import { useState } from 'react';
import { useTrackTime } from "../../hooks/attendance/attendanceMutation";
import { useTranslation } from "react-i18next";
import { useUser } from "../../hooks/user";
// Types
import {
    Shift, Attendance
} from "@amzn/red-velvet-api";


export interface TimeModalProps {
    shift: Shift,
    attendance?: Attendance,
    visible: boolean,
    onDismiss: NonNullable<ModalProps["onDismiss"]> // This makes the optional onDismiss function from the Modal props into a required field
}

const MIN_PER_HOUR = 60;
const MILLS_PER_MIN = 1000 * 60;
const SHORT_SHIFT_HOURS = 8;
const SHORT_SHIFT_ALLOWED_EXTRA_HOURS = 2;

export function formatMinutes(minutes: number) {
    const hours = Math.floor(minutes / MIN_PER_HOUR);
    const mins = minutes % MIN_PER_HOUR;
    
    return `${hours < 10 ? "0" + hours : hours}:${mins < 10 ? "0" + mins: mins}`
}

export function TimeModal(props: TimeModalProps & ModalProps) {
    const { t } = useTranslation(["shifts", "translation"]);  
    const user = useUser();
    
    const shiftMins = props.shift.startDateTime && props.shift.endDateTime ? 
        Math.floor((props.shift.endDateTime.getTime() - props.shift.startDateTime.getTime()) / MILLS_PER_MIN) : 0;
    
    const [time, setTime] = useState(props.attendance ? formatMinutes(props.attendance.minutes) : 
        shiftMins ? formatMinutes(shiftMins) : "");
    const [error, setError] = useState("");
    const [warning, setWarning] = useState("");
    
    const timeMutation = useTrackTime(props.shift.eventId, props.shift.shiftId);
    
    function parseTime(rawTime: string) {
        let hours = 0;
        let min = 0;
        const timeChunks = rawTime.split(":");
        if(timeChunks) {
            if(timeChunks.length > 0){
                hours = timeChunks[0] ? parseInt(timeChunks[0]) : 0;
            }
            
            if(timeChunks.length > 1){
                min = timeChunks[1] ? parseInt(timeChunks[1]) : 0;
            }
        }
        
        if(hours === 0 && min === 0) {
            setError(t("timeModal.noTime"));
        } else {
            setError("");
        }
        
        const minutesReported = hours * MIN_PER_HOUR + min;
        
        if(shiftMins !== 0 && shiftMins <= SHORT_SHIFT_HOURS * MIN_PER_HOUR && minutesReported - shiftMins >= SHORT_SHIFT_ALLOWED_EXTRA_HOURS * MIN_PER_HOUR) {
            setWarning(t("timeModal.shortShiftHighTime"));
        } else if(minutesReported > SHORT_SHIFT_HOURS * MIN_PER_HOUR) {
            setWarning(t("timeModal.longShiftHighTime"));
        } else if(min >= MIN_PER_HOUR) {
            hours += Math.floor(min / MIN_PER_HOUR);
            min = min % MIN_PER_HOUR;
            setWarning(t("timeModal.tooManySections", { hours, min }));
        } else if(timeChunks.length > 2) {
            setWarning(t("timeModal.tooManySections", { hours, min }));
        } else {
            setWarning("");
        }
    
        return minutesReported;
    }

    return (
        <Modal
            {...props}
            visible={props.visible}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={() => props.onDismiss(new CustomEvent<ModalProps.DismissDetail>('closeButton'))}>{t('timeModal.cancel')}</Button>
                        <Button 
                            variant="primary" 
                            loading={timeMutation.isPending}
                            disabled={error !== "" || time === ""}
                            data-aci-analytics-name="shifts_reportTime"
                            onClick={() => {
                                const minutes = parseTime(time);
                                if(minutes !== 0) {
                                    timeMutation.mutate({ eventId: props.shift.eventId, shiftId: props.shift.shiftId, alias: user.userId, minutes }, {
                                        onError: (error) => {
                                            setError(t('timeModal.error', { error: error.message}));
                                        },
                                        onSuccess: () => {
                                            // Note: the 'closeButton' input here indicates that the modal was closed by an action within the modal
                                            // since the dismiss function only accepts the following reasons: 'closeButton', 'overlay', 'keyboard'
                                            // and overlay and keyboard indicate user events that are external to the modal
                                            props.onDismiss(new CustomEvent<ModalProps.DismissDetail>('closeButton'));
                                        }
                                    });
                                }
                            }}>
                            {t('timeModal.trackTime')}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={t('timeModal.title')}
            closeAriaLabel={t('timeModal.cancel')}>
            <form onSubmit={e => e.preventDefault()}>
                <Form>
                    <FormField 
                        label={t('timeModal.timeVolunteered')}
                        warningText={warning}
                        errorText={error}>
                        <Input
                            onChange={({ detail }) => {
                                // Strip out anything other than digits or :
                                const rawTime = (detail.value.match(/[\d:]/g) || []).join("");
                                setTime(rawTime);
                                parseTime(rawTime);
                            }}
                            onBlur={() => parseTime(time)}
                            value={time}
                            placeholder="hh:mm"
                            ariaRequired
                            autoFocus={props.visible}
                            inputMode="numeric"
                            step={1}/>
                    </FormField>
                </Form>
            </form>
        </Modal>
    );
}
