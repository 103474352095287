import * as React from "react";
import { SearchTable } from './searchTable';
import { AttendeeTable } from './attendeeTable';
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";



export function AttendeeTracker() {
	return (
		<SpaceBetween direction="vertical" size="l">
			<SearchTable />
			<AttendeeTable />
		</SpaceBetween>
		);
}