/* istanbul ignore file */
import { Analytics } from "aws-amplify";
import { ConsoleLogger as Logger } from '@aws-amplify/core';
import { v4 as uuidv4 } from 'uuid';
import { CookieHelper } from "../../utils/cookieHelper";
import { getAuthAlias } from "../../utils/auth";

const VC_SESSION_ID_ATTRIBUTE = "vc_session_id"
const VC_SESSION_START_ATTRIBUTE = "vc_session_start"
const VC_SESSION_END_ATTRIBUTE = "vc_session_end"
const VC_SESSION_DURATION_MS_ATTRIBUTE = "vc_session_duration_ms"
const VC_SESSION_USER_ALIAS_ATTRIBUTE = "vc_session_user_alias"

const VC_SESSION_START_EVENT_TYPE = "vc_session_start"
const VC_SESSION_END_EVENT_TYPE = "vc_session_end"

/**
 * This package is designed to work around a limitation of the Amplify pinpoint implementation's
 * session tracking.
 *
 * Specifically  (https://issues.amazon.com/issues/cce-2779)
 *   1. sessions in Amplify start and end based on focus, not based on closing the tab or
 * navigating to or away from the application. - https://docs.amplify.aws/lib/analytics/autotrack/q/platform/js/#session-tracking
 *   2. pageViews are not aligned with sessions, meaning a new session does not trigger a re-emit
 * of a pageView - this appears to be a bug, not a documented feature.
 *
 * Combined, these problems make it difficult to get useful analytics from sessions.
 *
 * To use this package
 * 1. use trackSessions({attrributes:...}) to configure our custom session tracker. This will
 *   generate vc_start_session and vc_end_session events
 * 2. use getSessionAttributes() in your generated events to get the session attributes
 *   * vc_session_id <- unique UUID for our session
 *   * vc_session_start <-- start time of the session
 *
 * What is a vc_session?
 *
 * When does it start?
 *
 * A vc_session starts when the trackSessions code is executed if an existing session has not
 * already been started.
 *
 * When does it end?
 *  1. The user navigates away from the page, or closes the page, or otherwise causes a page unload event to trigger.
 *  2. The user causes the session location storage to clear (generally due to exiting the browser tab or window, but not
 * necessarily navigating away from the page.)
 *
 * For more information on the problem, solutions considered, etc. please refer to https://issues.amazon.com/issues/cce-2779
 */

type Session = {
    id: string
    start: number
}

/**
 * Allow the user to specify custom attributes al la the built in
 * pinpont trackers.
 */
type SessionTrackerConfig = {
    attributes: Record<string, string> | (() => Record<string, string>)
}


const VC_SESSION_INFO_STORAGE_KEY = "vc_session_info";

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => { };

const logger = new Logger('VCSessionTracker');

/**
 * Execute code based on if a session is curently configured or not.
 *
 * @param thencb callback if there is a session with the session info
 * @param elsecb callback if there is not a session.
 * @returns nothing
 */
const ifInSession = (thenCb: (s: Session) => void, elseCb?: () => void) => {
    elseCb = elseCb || noop;
    const session = getActiveSession()
    if (session !== undefined) {
        thenCb(session)
        return;
    }
    elseCb()
}

/**
 * Create a new session ID and timestamp and write them to session storage.
 * This is done regardles of what is already in storage.
 */
const initializeSessionInfo = () => {
    const start = (new Date()).getTime();
    //NOTE: uuidv4 is not performant.. TBD, replace with non-crypto version if we care.
    const cookies = new CookieHelper();
    const id = cookies.get('session_uuid') || uuidv4()

    const session = { id, start }
    setActiveSesionInSessionStorage(session);
    return session;
}

/**
 *  get the active session from session storage, if it exists. undefined otherwise.
 */
const getActiveSession = (): Session | undefined => {
    const sessionstring = sessionStorage.getItem(VC_SESSION_INFO_STORAGE_KEY);
    if (sessionstring === null) {
        return undefined;
    }
    try {
        const parsed = JSON.parse(sessionstring);
        const id = parsed["id"];
        const start = parsed["start"];
        if (
            (id != null && typeof (id) == "string") &&
            (start != null && typeof (start) == "number")) {
            return { id, start }
        } else {
            throw new Error(`sessionStorage session info doesn't have the expected format: ${parsed}`)
        }

    } catch (e) {
        logger.log("Unable to parse stored session: " + e)
    }
    return undefined;
}

const getActiveUserAlias = (): string | undefined => {
    return getAuthAlias() ?? undefined;
}

/**
 * write (or overwrite) whatever is currently in sessionStorage for the session with the provided session.
 */
const setActiveSesionInSessionStorage = (session: Session) => {
    sessionStorage.setItem(VC_SESSION_INFO_STORAGE_KEY, JSON.stringify(session))
}

/**
 * Clear anything in session storage related to the session.
 */
const clearSessionFromSessionStorage = () => {
    sessionStorage.removeItem(VC_SESSION_INFO_STORAGE_KEY);
}

/**
 * If there is an active session return the "vc_session_id" and "vc_session_start" attributes.
 * otherwise return an empty record.
 */
export const getSessionAttributes = (): Record<string, string> => {
    const session = getActiveSession();
    const attributes: Record<string, string> = session !== undefined ? {
        [VC_SESSION_ID_ATTRIBUTE]: session.id,
        [VC_SESSION_START_ATTRIBUTE]: session.start.toString()
    } : {};
    const alias = getActiveUserAlias();
    const alias_attributes: Record<string, string> = alias !== undefined ? {
        [VC_SESSION_USER_ALIAS_ATTRIBUTE]: alias
    } : {};
    return {
        ...attributes,
        ...alias_attributes
    }
}

/**
 * enable our custom session tracking designed to track a more reasonable version of
 * "session" than the default which equates window focus to session. (see https://issues.amazon.com/issues/cce-2779)
 *
 * This will emit two new events
 * 1. vc_start_session
 * 2. vc_end_session
 *
 * with the following (as aproripate) attributes:
 * 1. vc_session_id
 * 2. vc_session_start
 * 3. vc_session_end (end only)
 * 4. vc_session_duration (end only)
 */
export const trackSessions = (config: SessionTrackerConfig) => {
    new SessionTracker(config);
}

class SessionTracker {
    private config: SessionTrackerConfig;

    constructor(config: SessionTrackerConfig) {
        this.config = config
        //if there is no session yet, we create it on instantiation.
        ifInSession((session) => {
            logger.log(`Session ${session.id} already started. Keeping existing session`)
        }, () => {
            this.startSession();
        });
        logger.log("Configuring a listener for page unload")
        window.addEventListener('beforeunload', () => {
            this.endSession()
        }, false);
    }

    private startSession() {
        logger.log("Starting session");
        const session = initializeSessionInfo();
        this.sendStartSessionEvent(session);
    }

    private sendStartSessionEvent(session: Session) {
        logger.log("emitting session start event");
        const attributes = this.buildAttributes(getSessionAttributes());
        Analytics.record({
            name: VC_SESSION_START_EVENT_TYPE,
            attributes
        }).catch(e => {
            logger.debug('record session start event failed.', e);
        })
    }

    private endSession() {
        logger.log("Got page unload event");
        logger.log("emitting session end event");

        ifInSession((session) => {
            const end = new Date().getTime()
            const duration = end - session.start
            const endAttributes = {
                [VC_SESSION_END_ATTRIBUTE]: end.toString(),
                [VC_SESSION_DURATION_MS_ATTRIBUTE]: duration.toString()
            }
            const attributes = this.buildAttributes(Object.assign(endAttributes, getSessionAttributes()));

            //this appears to, in practice, actually execute close enough to synchronously
            //to happen, but there is no guarauntee and it does not work, for instance,
            //in tests.
            //Either way we con't control that part of Analytics.
            Analytics.record({
                name: VC_SESSION_END_EVENT_TYPE,
                attributes,
                immediate: true
            }).catch(e => {
                logger.debug('record session stop event failed.', e);
            }).finally(() => {
                logger.log("Clearing the session info from session storage");
                clearSessionFromSessionStorage();
            })
        }, () => {
            logger.log("There was no session to end. Emitting nothing");
        })

    }

    private buildAttributes(base: Record<string, string>) {
        const customAttrs =
            typeof this.config.attributes === 'function'
                ? this.config.attributes()
                : this.config.attributes;

        return Object.assign(base, customAttrs)
    }
}
