// GOLDEN PATH CODE
// This code demonstrates how to use linked types to ensure consistent
// input and output types in in third-party libraries that do not know
// or care about our custom defined types
import { 
    RedVelvetApi,
    GetPeopleCommandOutput,
    GetShiftsByEventCommandOutput, 
    GetSignupsByQueryParamCommandOutput, 
    GetDetailedSignupsByQueryParamCommandOutput,
    GetAttendancesByQueryParamCommandOutput
} from "@amzn/red-velvet-api";
import { getClient } from "../utils/getClient";

// GOLDEN PATH NOTE
// Enforcing the query key to be the methodName and methodParameters for the RedVelvetApi client
// means that our queries can be strictly opinionated on how to call the client, and ensures that all
// parameters that are passed into the RedVelvetApi client are also passed into the react-query client
// so that caching works out-of-the-box with minimal need for developers to think about it
export type RedVelvetApiMethod = "getPeople" | "getSignupsByQueryParam" | "getShiftsByEvent" | "getDetailedSignupsByQueryParam" | "getAttendancesByQueryParam"; // Add more method names here as needed
export type RedVelvetApiMethodParameters<K extends RedVelvetApiMethod> = Parameters<RedVelvetApi[K]>[0]; // This relies on the client generator being consistent

// NOTE: When adding new methods here, you should also add them to the integration tests
// setup suite at RedVelvetReactWebsite/src/__test__/events_support.tsx:320
export type RedVelvetApiMethodOutput<K extends RedVelvetApiMethod> = 
  K extends "getPeople" ? GetPeopleCommandOutput :
  K extends "getShiftsByEvent" ? GetShiftsByEventCommandOutput :
  K extends "getSignupsByQueryParam" ? GetSignupsByQueryParamCommandOutput :
  K extends "getDetailedSignupsByQueryParam" ? GetDetailedSignupsByQueryParamCommandOutput : 
  K extends "getAttendancesByQueryParam" ? GetAttendancesByQueryParamCommandOutput : 
  never;

export type UseAllPagesRedVelvetQueryOptions<K extends RedVelvetApiMethod, T>=  {
  enabled?:boolean,
  redVelvetQueryKey:[K, RedVelvetApiMethodParameters<K>] // Forces query key to be [methodName, methodParameters]
  converter: (output: RedVelvetApiMethodOutput<K>) => PagedResponse<T>
}
type PagedResponse<T> = {
  items:T[],
  next?:string
}

// This is a hacky work-around to the fact that the Smithy client uses overloaded methods, which return a void type 
// if accessed via indexing (ie. client[methodName])
// When adding a new command to the list of paginated queries, add the name of the method to the RedVelvetApiMethod type,
// and then add the client call to this method
export async function callClientByMethodName<K extends RedVelvetApiMethod>(methodName: RedVelvetApiMethod, parameters: RedVelvetApiMethodParameters<K>): Promise<RedVelvetApiMethodOutput<K>> {
  const client = getClient();
  switch(methodName) {
    case "getPeople":
      return await client.getPeople(parameters as RedVelvetApiMethodParameters<"getPeople">) as RedVelvetApiMethodOutput<K>;
    case "getSignupsByQueryParam":
      return await client.getSignupsByQueryParam(parameters as RedVelvetApiMethodParameters<"getSignupsByQueryParam">) as RedVelvetApiMethodOutput<K>;
    case "getShiftsByEvent":
      return await client.getShiftsByEvent(parameters as RedVelvetApiMethodParameters<"getShiftsByEvent">) as RedVelvetApiMethodOutput<K>;
    case "getDetailedSignupsByQueryParam":
      return await client.getDetailedSignupsByQueryParam(parameters as RedVelvetApiMethodParameters<"getDetailedSignupsByQueryParam">) as RedVelvetApiMethodOutput<K>;
    case "getAttendancesByQueryParam":
      return await client.getAttendancesByQueryParam(parameters as RedVelvetApiMethodParameters<"getAttendancesByQueryParam">) as RedVelvetApiMethodOutput<K>;
  }
}
