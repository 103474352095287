import React from "react";
import Form from "@amzn/awsui-components-react/polaris/form";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import SegmentedControl from "@amzn/awsui-components-react/polaris/segmented-control";
import {
  getOptionFromMultiValues,
  getOptionFromValue,
  getValueFromSearchParam,
} from "../utils/url";
import {Container, Header, Input, Multiselect, Button } from "@amzn/awsui-components-react";
import Select, {SelectProps} from "@amzn/awsui-components-react/polaris/select";
import TimeInput from "@amzn/awsui-components-react/polaris/time-input";
import { AbsoluteDatePicker} from "./absoluteDatePicker";
import {locationOptions} from "../data/locations";
import {useTranslation} from "react-i18next";
import {useSharedFilters} from "../hooks/filters";
import Autosuggest from "@amzn/awsui-components-react/polaris/autosuggest";
import { useSearchParams } from "react-router-dom";

const latlongRegex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;

export function AdvancedSearch () {
  const {
    filters,
    updaters,
    setValueWithDebounce,
    setValueWithoutDebounce,
    setMultiValueWithoutDebounce,
    setAutocompleteValueWithDebounce,
    resetFilters
  } = useSharedFilters();

  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const durationOptions = t('durationOptions', { returnObjects: true }) as SelectProps.Options;
  const daysOfWeekOptions = t('daysOfWeekOptions', { returnObjects: true }) as SelectProps.Options;

  return (
    <div className="advancedSearch">
      <SpaceBetween direction="vertical" size="s">
      <Header variant="h2" actions={ <Button
        disabled={filters.activeFilterCount <= 0}
        onClick={() => resetFilters()}
        data-external-analytics-on="click"
        data-external-analytics-name="resetFilters"
        data-aci-analytics-name="resetFilters">{t("advancedSearch.reset")}</Button>}>
        {t("advancedSearch.filterEvents", { count: filters.activeFilterCount })}
      </Header>
      <Form>
        <SpaceBetween direction="vertical" size="xs">
           <Container>
            <FormField label={t("advancedSearch.dateRange")}>
              <AbsoluteDatePicker
                onChange={(value) => {
                  const valueSet = value != null && value.type === "absolute";
                  const startDate = valueSet ? value.startDate : "";
                  const endDate = valueSet ? value.endDate : "";
                  setValueWithoutDebounce("start_timestamp", startDate);
                  setValueWithoutDebounce("end_timestamp", endDate);
                }}
                value={filters.dateRange}/>
            </FormField>
          </Container>
          <Container>
            <FormField label={t("advancedSearch.daysOfWeek")}>
              <Multiselect
                data-testid="advancedSearch.days_of_week"
                onChange={({ detail }) => setMultiValueWithoutDebounce("days_of_week", detail.selectedOptions)}
                selectedOptions={getOptionFromMultiValues(getValueFromSearchParam(searchParams, "days_of_week", "[]"), daysOfWeekOptions)}
                options={daysOfWeekOptions}
                keepOpen={true}
              />
            </FormField>
          </Container>
          <Container>
            <FormField label={t("advancedSearch.type")}>
              <div className="activityTypeOptions">
                <SegmentedControl
                  options={[
                    { text: t("advancedSearch.allType"), id: "all"},
                    { text: t("advancedSearch.physicalType"), id: "in-person"},
                    { text: t("advancedSearch.virtualType"), id: "virtual"}
                  ]}
                  onChange={ ({detail}) => setValueWithoutDebounce("activity_location", detail.selectedId)}
                  selectedId={filters.activityLocation}
                  label={t("advancedSearch.type")}
                  font-size={10}
                />
              </div>
            </FormField>
          </Container>
          <Container>
            <FormField label={t("advancedSearch.location")}>
              <Autosuggest
                onChange={({ detail }) => {
                  setAutocompleteValueWithDebounce("volunteer_location", detail.value || "", latlongRegex, locationOptions, updaters.setArea) 
                }}
                value={filters.area}
                options={locationOptions}
                virtualScroll/>
            </FormField>
          </Container>
          <Container>
            <FormField label={t("advancedSearch.openSpots")}>
              <Input
                onChange={({ detail }) => setValueWithDebounce("spots", detail.value, updaters.setSpots)}
                type="number"
                value={filters.spots}/>
            </FormField>
          </Container>
          <Container>
            <FormField label={t("advancedSearch.activityLength")}>
              <Select
                onChange={({ detail }) => setValueWithoutDebounce("activity_length", detail.selectedOption.value || "")}
                selectedOption={getOptionFromValue(filters.activityLength, durationOptions)}
                options={durationOptions}/>
            </FormField>
          </Container>
          <Container>
            <FormField label={t("advancedSearch.startsAfter")}>
              <TimeInput
                onChange={({ detail }) => setValueWithDebounce("start_time", detail.value, updaters.setStartTime)}
                value={filters.startTime}
                format="hh:mm"
                placeholder={t("advancedSearch.timeFormat")}/>
            </FormField>
          </Container>
          <Container>
            <FormField label={t("advancedSearch.endsBefore")}>
              <TimeInput
                onChange={({ detail }) => setValueWithDebounce("end_time", detail.value, updaters.setEndTime)}
                value={filters.endTime}
                format="hh:mm"
                placeholder={t("advancedSearch.timeFormat")}/>
            </FormField>
          </Container>
          <Container>
              <FormField label={t("advancedSearch.frequency")} >
                <div className="frequencyOptions">
                  <SegmentedControl
                      options={[
                        { text: t("advancedSearch.frequencyAll"), id:"all"},
                        { text: t("advancedSearch.frequencyOnce"), id:"time_bounded"},
                        { text: t("advancedSearch.frequencyRepeating"), id:"ongoing"}
                      ]}
                      onChange={ ({detail}) => setValueWithoutDebounce("frequency", detail.selectedId)}
                      selectedId={filters.frequency}
                      label={t("advancedSearch.frequency")}
                  />
                </div>
              </FormField>
          </Container>
          <Container>
            <FormField label={t("advancedSearch.search")}>
              <Input
                data-testid="advancedSearch.search"
                onChange={({ detail }) => setValueWithDebounce("query", detail.value, updaters.setQuery)}
                value={filters.query}/>
            </FormField>
          </Container>

      </SpaceBetween>
      </Form>
      </SpaceBetween>
    </div>
  );
}