export interface AnalyticsConfig {
  appId: string,
  region: string,
  identityPoolId: string,
  katalServiceNamePostfix: string
}

export interface AuthConfig {
  userPoolClientId: string,
  userPoolId: string,
  region: string,
  identityPoolId: string,
  domain: string
}
export interface Config {
  urls: string[],
  api: string,
  smithyApi: string,
  analytics: AnalyticsConfig,
  auth: AuthConfig
}
export const config = {
  local: {
    urls: [`http://localhost:3000`],
    // Uses alpha data
    // To use dev stack data (including for locally running integ tests), replace with
    // api: 'https://api.{user}.red-velvet-internal-prototype.aitc.gca.amazon.dev',
    // smithyApi: 'https://rest.{user}.red-velvet-internal-prototype.aitc.gca.amazon.dev',
    // while changing {user} to your alias
    api: 'https://api.alpha.red-velvet-internal-prototype.aitc.gca.amazon.dev',
    smithyApi: 'https://rest.alpha.red-velvet-internal-prototype.aitc.gca.amazon.dev',
    analytics: {
      appId: '66e48577f9764790bada21e60d36edc3',
      region: 'us-east-1',
      identityPoolId: 'us-east-1:91090333-8f28-4d05-9856-c626c6cc7f2a',
      katalServiceNamePostfix: 'local'
    },
    auth: {
      userPoolClientId: '2dll4kjhdco66rblfg03ppu0pr',
      userPoolId: "us-east-1_MaTfiCa4k",
      region: 'us-east-1',
      identityPoolId: "us-east-1:a1185af8-b1a3-4f73-858b-77595f0248c4",
      domain: "red-velvet-alpha.auth.us-east-1.amazoncognito.com"
    }
  },
  dev: {
    urls: [`https://dev.red-velvet-internal-prototype.aitc.gca.amazon.dev`],
    // Edit to use your alias
    api: 'https://api.{user}.red-velvet-internal-prototype.aitc.gca.amazon.dev',
    //Edit to point to the endpoint in your stack (sorry no alias yet)
    smithyApi: 'https://rest.{user}.red-velvet-internal-prototype.aitc.gca.amazon.dev',
    analytics: {
      appId: '66e48577f9764790bada21e60d36edc3',
      region: 'us-east-1',
      identityPoolId: 'us-east-1:91090333-8f28-4d05-9856-c626c6cc7f2a',
      katalServiceNamePostfix: 'dev'
    },
    auth: {
      userPoolClientId: '2dll4kjhdco66rblfg03ppu0pr',
      userPoolId: "us-east-1_MaTfiCa4k",
      region: 'us-east-1',
      identityPoolId: "us-east-1:a1185af8-b1a3-4f73-858b-77595f0248c4",
      domain: "red-velvet-alpha.auth.us-east-1.amazoncognito.com"
    }
  },
  alpha: {
    urls: [`https://alpha.red-velvet-internal-prototype.aitc.gca.amazon.dev`],
    api: 'https://api.alpha.red-velvet-internal-prototype.aitc.gca.amazon.dev',
    smithyApi: 'https://rest.alpha.red-velvet-internal-prototype.aitc.gca.amazon.dev',
    analytics: {
      appId: '66e48577f9764790bada21e60d36edc3',
      region: 'us-east-1',
      identityPoolId: 'us-east-1:91090333-8f28-4d05-9856-c626c6cc7f2a',
      katalServiceNamePostfix: 'alpha'
    },
    auth: {
      userPoolClientId: '2dll4kjhdco66rblfg03ppu0pr',
      userPoolId: "us-east-1_MaTfiCa4k",
      region: 'us-east-1',
      identityPoolId: "us-east-1:a1185af8-b1a3-4f73-858b-77595f0248c4",
      domain: "red-velvet-alpha.auth.us-east-1.amazoncognito.com"
    }
  },
  beta: {
    urls: [`https://beta.red-velvet-internal-prototype.aitc.gca.amazon.dev`],
    api: 'https://api.beta.red-velvet-internal-prototype.aitc.gca.amazon.dev',
    smithyApi: 'https://rest.beta.red-velvet-internal-prototype.aitc.gca.amazon.dev',
    analytics: {
      appId: 'f466fce9463b41439fedfe879a2e21d0',
      region: 'us-east-1',
      identityPoolId: 'us-east-1:64fd81b2-d4cd-4c97-a7ae-5b761e683c03',
      katalServiceNamePostfix: 'beta'
    },
    auth: {
      userPoolClientId: '5e11u9vm6787g0l2obl2178cc4',
      userPoolId: "us-east-1_zwmDx1iW6",
      region: 'us-east-1',
      identityPoolId: "us-east-1:aa2e2d74-e78e-4f5f-bf03-2146d6cb55c8",
      domain: "red-velvet-beta.auth.us-east-1.amazoncognito.com"
    }
  },
  prod: {
    urls: ["https://www.volunteercenter.amazon.dev", `https://prod.red-velvet-internal-prototype.aitc.gca.amazon.dev`],
    api: 'https://api.prod.red-velvet-internal-prototype.aitc.gca.amazon.dev',
    smithyApi: 'https://rest.prod.red-velvet-internal-prototype.aitc.gca.amazon.dev',
    analytics: {
      appId: '57d71f4d8b0a4ca285deff90d3496053',
      region: 'us-east-1',
      identityPoolId: 'us-east-1:2bb69465-078b-4af5-86e5-bcebcd1b51d9',
      katalServiceNamePostfix: 'prod'
    },
    auth: {
      userPoolClientId: '318ft8p111tcdljkag9jhdc8qj',
      userPoolId: "us-east-1_KChDk3A4B",
      region: 'us-east-1',
      identityPoolId: "us-east-1:5e735aa2-f92c-454d-8f58-f6ac832f16c1",
      domain: "red-velvet-prod.auth.us-east-1.amazoncognito.com"
    }
  }
};
