import { useTranslation } from "react-i18next";
import { TableProps } from "@amzn/awsui-components-react/polaris/table";

export function useTableStrings<T>(tablePrefix: string, selectionLabel: (item: T) => string, namespaces: string[] = ['translation']): 
  { ariaLabels: TableProps.AriaLabels<T>, renderAriaLive: (data: TableProps.LiveAnnouncement) => string, enableKeyboardNavigation: true, loadingText: string }  {

  const { t } = useTranslation(namespaces);

  return {
    ariaLabels: {
          selectionGroupLabel: t([`${tablePrefix}.selectionGroupLabel`, `table.selectionGroupLabel`]),
          allItemsSelectionLabel: ({ selectedItems }) => t([`${tablePrefix}.allItemsSelectionLabel`, `table.allItemsSelectionLabel`], { count: selectedItems.length }),
          itemSelectionLabel: ({ selectedItems }, item) => selectionLabel(item)
      },
      renderAriaLive: (liveValues) => t([`${tablePrefix}.renderAriaLive`, `table.renderAriaLive`], liveValues),
        loadingText: t([`${tablePrefix}.loading`, `table.loading`]),
      enableKeyboardNavigation: true
    }
}