import { Cookies } from "react-cookie";

export interface CookieOptions {
    expires?: Date
    maxAge?: number
    path?: string
    domain?: string
    secure?: boolean
    httpOnly?: boolean
}
/**
 * CookieHelper provides a thin wrapper around react-cookie for testing purposes, which should give confidence that
 * assumptions around how the library works are accurate
 * todo: ff - after discussions on path forward, remove thin wrapper or follow the CookieProvider pattern
 *       https://www.npmjs.com/package/react-cookie
 **/
export class CookieHelper {
    private cookies = new Cookies()

    public get = (name: string) => {
        return this.cookies.get(name)
    }

    public set = (name:string, value:string, options?:CookieOptions) => {
        return this.cookies.set(name, value, options)
    }

    public remove = (name:string, options?:CookieOptions) => {
        return this.cookies.remove(name, options)
    }
}
