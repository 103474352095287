import { useTranslation } from "react-i18next";
import { TableProps } from "@amzn/awsui-components-react/polaris/table";

export type ColumnMap<T> = { 
  [key in keyof T]: (item: T) => JSX.Element | string 
}

export type ColumnData<T> = {
  columnDefinitions: TableProps.ColumnDefinition<T>[],
  columnDisplay: TableProps.ColumnDisplayProperties[]
}

export function useTableColumns<T>(columnMap: ColumnMap<T>, tableName: string, namespaces: string[] = ['translation']): ColumnData<T> {
  const { t } = useTranslation(namespaces);

  return {
    columnDisplay: Object.keys(columnMap).map(key => ({ 
      id: key, 
      visible: true 
    })),
    columnDefinitions: Object.entries(columnMap).map((entry) => { 
      const [key, mapValue] = entry;
      return {
        id: key, 
        header: t(`${tableName}.columns.${key}`),
        // This cast is required because Object.entries converts the value to unknown, even though it is defined in the ColumnMap type
        cell: mapValue as (item: T) => JSX.Element | string 
      }
    })
  }
}