export const dateDisplayOptions: Intl.DateTimeFormatOptions = {
  month: "long",
  day: "numeric",
  year: "numeric"
};

export const timeDisplayOptions: Intl.DateTimeFormatOptions = {
  timeZoneName: "shortGeneric",
  hour: "numeric",
  minute: "numeric"
}

export function asTimeString(date:Date, selectedTimezone?:string, locales?: Intl.LocalesArgument, options?:{removeTimeZone:boolean}):string {
  const extraOptions = options?.removeTimeZone ? {
    timeZone: undefined
  } : {};

  return date.toLocaleString(locales,
    { ...timeDisplayOptions, ...extraOptions, timeZone: selectedTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone });
}

export function asDateString(date:Date, selectedTimezone?:string, locales?: Intl.LocalesArgument):string {
  return date.toLocaleDateString(locales, { ...dateDisplayOptions, timeZone: selectedTimezone });
}

