import React from "react";
import { Box, Button, Header, Pagination, Spinner } from "@amzn/awsui-components-react";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import { SearchEventCard } from "./searchEventCard";
import { useSearchParams } from "react-router-dom";
import { SetURLSearchParams, getIntFromUrlParams, urlUpdater } from "../../utils/url";
import { EventPreferences } from "../eventPreferences";
import { useCallback, useEffect, useRef } from "react";
import { DEFAULT_EVENTS_PAGE, DEFAULT_EVENTS_PAGE_SIZE } from "../../api/events";
import { useTranslation } from "react-i18next";
import { publishMetric, publishMetricsWithAttributes } from "../analytics";
import { publishKatalMetric } from "../katalAnalytics";
import { useBetterEventsWithSearchParams } from "../../hooks/betterEvents/betterEvents";

interface TopPaginationProps {
  paginationType: string | null,
  missingPrevEvents: boolean,
  setSearchParams: SetURLSearchParams,
  loading: boolean,
  currPage: number,
  total: number,
  pageSize: number
}

function TopPaginationControl({ loading, currPage, total, pageSize, paginationType, missingPrevEvents, setSearchParams }: TopPaginationProps) {
  switch (paginationType) {
    case "load":
      return TopPaginationControlResetToFirst({ loading, currPage, total, pageSize, paginationType, missingPrevEvents, setSearchParams });
    case "infinite":
      return TopPaginationControlResetToFirst({ loading, currPage, total, pageSize, paginationType, missingPrevEvents, setSearchParams });
    case "pages":
      return TopPaginationControlForPages({ loading, currPage, total, pageSize, paginationType, missingPrevEvents, setSearchParams });
    default: // Same as load https://issues.amazon.com/issues/cce-2734
      return TopPaginationControlResetToFirst({ loading, currPage, total, pageSize, paginationType, missingPrevEvents, setSearchParams });
  }
}

function TopPaginationControlResetToFirst({ loading, currPage, total, pageSize, paginationType, missingPrevEvents, setSearchParams }: TopPaginationProps) {
  const { t } = useTranslation();
  const updateUrlValue = urlUpdater(setSearchParams);

  if (!missingPrevEvents) {
    return null;
  }

  return (<Button
    fullWidth
    variant="link"
    data-testid="resetToFirstPage"
    onClick={() => {
      updateUrlValue(["page", null]);
      publishMetric("resetToFirstPage");
      publishKatalMetric("Click", "resetToFirstPage");
    }}>{t("eventList.topPaginationControl.resetToFirstPage")}</Button>);
}

function TopPaginationControlForPages({ loading, currPage, total, pageSize, paginationType, missingPrevEvents, setSearchParams }: TopPaginationProps) {
  const { t } = useTranslation();
  const updateUrlValue = urlUpdater(setSearchParams);

  return (<Pagination
    data-testid="eventlist.topPaginationControl"
    disabled={loading}
    openEnd={loading}
    currentPageIndex={currPage}
    pagesCount={Math.ceil(total / pageSize)}
    ariaLabels={{
      nextPageLabel: t("eventList.topPaginationControl.next"),
      previousPageLabel: t("eventList.topPaginationControl.previous"),
      paginationLabel: t("eventList.topPaginationControl.label")
    }}
    onChange={({ detail }) => {
      publishMetricsWithAttributes("eventPageTop", "page", detail.currentPageIndex.toString());
      publishKatalMetric("eventPageTop", detail.currentPageIndex.toString());
      updateUrlValue(["page", detail.currentPageIndex.toString()]);
    }} />);
}

interface BottomPaginationProps {
  paginationType: string | null,
  itemCount: number,
  total: number,
  loading: boolean,
  pageNum: number,
  setSearchParams: SetURLSearchParams,
  nextLoader: React.MutableRefObject<null>
  isLastPage: boolean,
  currPage: number,
  pageSize: number
}

function BottomPaginationControl({ currPage, pageSize, paginationType, itemCount, total, loading,
  pageNum, setSearchParams, nextLoader, isLastPage }: BottomPaginationProps) {

  switch (paginationType) {
    case "load":
      return BottomPaginationControlForLoadMore({
        currPage, pageSize, paginationType, itemCount, total, loading,
        pageNum, setSearchParams, nextLoader, isLastPage
      });
    case "infinite":
      return BottomPaginationControlForInfiniteScroll({
        currPage, pageSize, paginationType, itemCount, total, loading,
        pageNum, setSearchParams, nextLoader, isLastPage
      });
    case "pages":
      return BottomPaginationControlForPages({
        currPage, pageSize, paginationType, itemCount, total, loading,
        pageNum, setSearchParams, nextLoader, isLastPage
      });
    default: // Same as load https://issues.amazon.com/issues/cce-2734
      return BottomPaginationControlForLoadMore({
        currPage, pageSize, paginationType, itemCount, total, loading,
        pageNum, setSearchParams, nextLoader, isLastPage
      });

  }
}

function BottomPaginationControlForLoadMore({ currPage, pageSize, paginationType, itemCount, total, loading,
  pageNum, setSearchParams, nextLoader, isLastPage }: BottomPaginationProps) {
  const { t } = useTranslation();
  const updateUrlValue = urlUpdater(setSearchParams);

  if (itemCount <= 0) {
    return null;
  }

  if (isLastPage) {
    return (<p>{t("eventList.bottomPaginationControl.numResultsDisplayed", { itemCount, total })}</p>);
  }

  return (<div>
    <p>{t("eventList.bottomPaginationControl.numResultsDisplayed", { itemCount, total })}</p>
    <Button
      fullWidth
      variant="primary"
      data-testid="loadMore"
      onClick={() => {
        const nextPage = pageNum + 1;
        publishMetricsWithAttributes("loadMore", "page", nextPage.toString());
        publishKatalMetric("loadMore", nextPage.toString());
        updateUrlValue(["page", nextPage.toString()]);
      }}
      disabled={loading}>
      {loading ?
        <span><Spinner /> {t("eventList.bottomPaginationControl.loadingMore")}</span> :
        <span>{t("eventList.bottomPaginationControl.loadMore")}</span>}
    </Button>
  </div>);
}

function BottomPaginationControlForInfiniteScroll({ currPage, pageSize, paginationType, itemCount, total, loading,
  pageNum, setSearchParams, nextLoader, isLastPage }: BottomPaginationProps) {
  const { t } = useTranslation();

  if (itemCount <= 0) {
    return null;
  }

  if (isLastPage) {
    return (<p>{t("eventList.bottomPaginationControl.numResultsDisplayed", { itemCount, total })}</p>);
  }

  return (<div ref={!loading ? nextLoader : null}>
    <p>{t("eventList.bottomPaginationControl.numResultsDisplayed", { itemCount, total })}</p>
    <Spinner />{loading ? <span> {t("eventList.bottomPaginationControl.loadingMore")}</span> : null}
  </div>);
}

function BottomPaginationControlForPages({ currPage, pageSize, paginationType, itemCount, total, loading,
  pageNum, setSearchParams, nextLoader, isLastPage }: BottomPaginationProps) {

  const { t } = useTranslation();
  const updateUrlValue = urlUpdater(setSearchParams);

  return (<Pagination
    data-testid="eventlist.bottomPaginationControl"
    disabled={loading}
    openEnd={loading}
    currentPageIndex={currPage}
    pagesCount={Math.ceil(total / pageSize)}
    ariaLabels={{
      nextPageLabel: t("eventList.topPaginationControl.next"),
      previousPageLabel: t("eventList.topPaginationControl.previous"),
      paginationLabel: t("eventList.topPaginationControl.label")
    }}
    onChange={({ detail }) => {
      publishMetricsWithAttributes("eventPageBottom", "page", detail.currentPageIndex.toString());
      publishKatalMetric("eventPageBottom", detail.currentPageIndex.toString());
      updateUrlValue(["page", detail.currentPageIndex.toString()]);
    }} />);
}

export function EventList() {
  const [{ loading, events, ids, total }, { clearAlert, signupUserToShift, cancelSignup }] = useBetterEventsWithSearchParams();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const currPage = getIntFromUrlParams(searchParams, "page", DEFAULT_EVENTS_PAGE);
  const pageSize = getIntFromUrlParams(searchParams, "pageSize", DEFAULT_EVENTS_PAGE_SIZE);
  const displayingEvents = !loading && ids.length > 0;
  const expectedResults = pageSize * currPage;
  const missingPrevEvents = displayingEvents && expectedResults - ids.length >= pageSize;
  const isLastPage = total - expectedResults <= 0;
  const nextLoader = useRef(null);
  const updateUrlValue = useCallback((...params: [key: string, value: string | null][]) => {
    urlUpdater(setSearchParams)(...params);
  }, [setSearchParams]);
  
  const handleObserver = useCallback((entries: IntersectionObserverEntry[]) => {
    const target = entries[0];
    if (target.isIntersecting && !loading) {
      const nextPage = currPage + 1;
      publishMetricsWithAttributes("infiniteLoader", "page", nextPage.toString());
      publishKatalMetric("infiniteLoader", nextPage.toString());
      updateUrlValue(["page", nextPage.toString()]);
    }
  }, [loading, currPage, updateUrlValue]);

  useEffect(() => {
    const option = { threshold: 1 };
    const observer = new IntersectionObserver(handleObserver, option);
    let loader: Element | null = null;
    if (nextLoader.current && !loading) {
      loader = nextLoader.current;
      observer.observe(loader);
    }

    return () => {
      if (loader) {
        observer.unobserve(loader);
      }
    };
  }, [handleObserver, loading]);
  
  return <div className="eventList">
    <Header actions={<EventPreferences resetEvents={() => updateUrlValue(["page", null])} />}>
      <TopPaginationControl
        paginationType={searchParams.get("pagination")}
        missingPrevEvents={missingPrevEvents}
        setSearchParams={setSearchParams}
        loading={loading}
        currPage={currPage}
        total={total}
        pageSize={pageSize} />
    </Header>
    {loading && ids.length === 0 ? <p><Spinner /> {t("eventList.loading")}</p> : <></>}
    {!loading && ids.length === 0 ? <Box textAlign="center" color="inherit"><p>{t("eventList.noneAvailable")}</p></Box> : <></>}
    <SpaceBetween direction="vertical" size="s">
      {ids.map(id => (<SearchEventCard
        key={id}
        event={events[id]}
        urlSearchParams={searchParams}
        dismissAlert={() => clearAlert(id)}
        doSignup={signupUserToShift}
        doCancel={cancelSignup} />))}
    </SpaceBetween>
    <BottomPaginationControl
      paginationType={searchParams.get("pagination")}
      itemCount={ids.length}
      currPage={currPage}
      total={total}
      pageSize={pageSize}
      loading={loading}
      pageNum={currPage}
      isLastPage={isLastPage}
      setSearchParams={setSearchParams}
      nextLoader={nextLoader} />
  </div>
}
