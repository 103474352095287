import {Error} from "../api/schemas/error";
import Table from "@amzn/awsui-components-react/polaris/table";
import { externalLinks } from "../data/externalLinks";
import {Event} from "../api/schemas/event";
import {BulkUpdate} from "../api/schemas/bulkUpdate";
import Header from "@amzn/awsui-components-react/polaris/header";
import {ExpandableSection, SpaceBetween, Link} from "@amzn/awsui-components-react";
import React from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useSearchParams} from "react-router-dom";
import { getValueFromSearchParam } from "../utils/url";

function BulkErrorsTable({ errors }: { errors: Error[] }) {
  const { t} = useTranslation();
  const [searchParams] = useSearchParams();
  const filename = getValueFromSearchParam(searchParams, "filename", "");

  return (
    <Table
      columnDefinitions={[
        {
          id: "eventId",
          header: "volunteer_opportunity_url",
          cell: item => (
            <Link external className="bulkErrorViewLink"
               href={externalLinks.benevity.event + `${item.id}?utm_source=${externalLinks.utm.source}&utm_medium=${'BulkUpdatePage'}&utm_campaign=${externalLinks.utm.campaign}`}
               data-external-analytics-on="click"
               data-external-analytics-name="bulkErrorClickToBenevity"
               data-external-analytics-attrs={`eventId:${item.id}`}
               data-aci-analytics-name={`bulkErrorClickToBenevity`}
               target="_blank"
               rel="noreferrer">
              {"/node/" + item.id}
            </Link>)
        },
        {
          id: "name",
          header: t("bulkUpdate.errorTitle"),
          cell: item => item.errors[0].title,
        },
        {
          id: "details",
          header: t("bulkUpdate.detailsTitle"),
          cell: item => item.errors[0].detail,
        }
      ]}
      stripedRows={true}
      variant="borderless"
      items={errors}
      loadingText={t("bulkUpdate.loading", { filename })}
      empty={<p>{t("bulkUpdate.failures_zero")}</p>}
    />
  );
}

function BulkEventsTable({ items }: { items: Event[] }) {
  const { t} = useTranslation();
  const [searchParams] = useSearchParams();

  const filename = getValueFromSearchParam(searchParams, "filename", "");

  return (
    <Table
      columnDefinitions={[
        {
          id: "eventId",
          header: "volunteer_opportunity_url",
          cell: item => (
            <Link external className="bulkSuccessViewLink"
               href={externalLinks.benevity.event + `${item.id}?utm_source=${externalLinks.utm.source}&utm_medium=${'BulkUpdatePage'}&utm_campaign=${externalLinks.utm.campaign}`}
               data-external-analytics-on="click"
               data-external-analytics-name="bulkErrorClickToBenevity"
               data-external-analytics-attrs={`eventId:${item.id}`}
               data-aci-analytics-name={`bulkErrorClickToBenevity`}
               target="_blank"
               rel="noreferrer">
              {"/node/" + item.id}
            </Link>)
        },
        {
          id: "name",
          header: "volunteer_opportunity_name",
          cell: item => item.attributes.name,
        },
        {
          id: "origin",
          header: "volunteer_opportunity_source",
          cell: item => item.attributes.origin,
        },
        {
          id: "start_datetime",
          header: "volunteer_opportunity_start_date",
          cell: item => item.attributes.start_datetime,
        },
        {
          id: "end_datetime",
          header: "volunteer_opportunity_end_date",
          cell: item => item.attributes.end_datetime,
        },
        {
          id: "contact_name",
          header: "volunteer_opportunity_contact_name",
          cell: item => item.attributes.contacts.primary.name,
        },
        {
          id: "contact_email",
          header: "volunteer_opportunity_contact_email",
          cell: item => item.attributes.contacts.primary.email,
        },
        {
          id: "contact_phone",
          header: "volunteer_opportunity_contact_phone",
          cell: item => item.attributes.contacts.primary.phone,
        },
        {
          id: "contact_2_name",
          header: "volunteer_opportunity_contact_2_name",
          cell: item => item.attributes.contacts.additional ? item.attributes.contacts.additional[0]?.name : null,
        },
        {
          id: "contact_2_email",
          header: "volunteer_opportunity_contact_2_email",
          cell: item => item.attributes.contacts.additional ? item.attributes.contacts.additional[0]?.email : null,
        },
        {
          id: "contact_2_phone",
          header: "volunteer_opportunity_contact_2_phone",
          cell: item => item.attributes.contacts.additional ? item.attributes.contacts.additional[0]?.phone : null,
        },
        {
          id: "contact_3_name",
          header: "volunteer_opportunity_contact_3_name",
          cell: item => item.attributes.contacts.additional ? item.attributes.contacts.additional[1]?.name : null,
        },
        {
          id: "contact_3_email",
          header: "volunteer_opportunity_contact_3_email",
          cell: item => item.attributes.contacts.additional ? item.attributes.contacts.additional[1]?.email : null,
        },
        {
          id: "contact_3_phone",
          header: "volunteer_opportunity_contact_3_phone",
          cell: item => item.attributes.contacts.additional ? item.attributes.contacts.additional[1]?.phone : null,
        },
        {
          id: "contact_4_name",
          header: "volunteer_opportunity_contact_4_name",
          cell: item => item.attributes.contacts.additional ? item.attributes.contacts.additional[2]?.name : null,
        },
        {
          id: "contact_4_email",
          header: "volunteer_opportunity_contact_4_email",
          cell: item => item.attributes.contacts.additional ? item.attributes.contacts.additional[2]?.email : null,
        },
        {
          id: "contact_4_phone",
          header: "volunteer_opportunity_contact_4_phone",
          cell: item => item.attributes.contacts.additional ? item.attributes.contacts.additional[2]?.phone : null,
        },
        {
          id: "contact_5_name",
          header: "volunteer_opportunity_contact_5_name",
          cell: item => item.attributes.contacts.additional ? item.attributes.contacts.additional[3]?.name : null,
        },
        {
          id: "contact_5_email",
          header: "volunteer_opportunity_contact_5_email",
          cell: item => item.attributes.contacts.additional ? item.attributes.contacts.additional[3]?.email : null,
        },
        {
          id: "contact_5_phone",
          header: "volunteer_opportunity_contact_5_phone",
          cell: item => item.attributes.contacts.additional ? item.attributes.contacts.additional[3]?.phone : null,
        },
        {
          id: "location_name",
          header: "volunteer_opportunity_location_name",
          cell: item => item.attributes.location?.name,
        },
        {
          id: "location_notes",
          header: "volunteer_opportunity_location_notes",
          cell: item => item.attributes.location?.notes,
        },
        {
          id: "location_address",
          header: "volunteer_opportunity_location_address",
          cell: item => item.attributes.location?.address,
        },
        {
          id: "location_city",
          header: "volunteer_opportunity_location_city",
          cell: item => item.attributes.location?.city,
        },
        {
          id: "location_state",
          header: "volunteer_opportunity_location_state",
          cell: item => item.attributes.location?.state,
        },
        {
          id: "location_country",
          header: "volunteer_opportunity_location_country",
          cell: item => item.attributes.location?.country,
        },
        {
          id: "location_postal_code",
          header: "volunteer_opportunity_location_postcode",
          cell: item => item.attributes.location?.postal_code,
        },
        {
          id: "summary",
          header: "volunteer_opportunity_summary",
          cell: item => item.attributes.summary,
        },
        {
          id: "description",
          header: "volunteer_opportunity_description",
          cell: item => item.attributes.description,
        },
        {
          id: "internal_notes",
          header: "volunteer_opportunity_internal_notes",
          cell: item => item.attributes.internal_notes,
        },
        {
          id: "approval_status",
          header: "volunteer_opportunity_status",
          cell: item => ({ "approved": "Approved", "denied": "Denied" }[item.attributes.approval_status] ?? "Queued"),
        },
        {
          id: "published",
          header: "volunteer_opportunity_publish_state",
          cell: item => ({ "true": "Active" }[item.attributes.published.toString()] ?? "Inactive"),
        },
        {
          id: "featured",
          header: "volunteer_opportunity_featured",
          cell: item => ({ "true": "Featured" }[item.attributes.featured.toString()] ?? ""),
        },
        {
          id: "search_access",
          header: "volunteer_opportunity_public",
          cell: item => ({ "closed": "By Invitation" }[item.attributes.search_access] ?? "Company-Wide Event"),
        },
        {
          id: "reoccurring_event",
          header: "volunteer_opportunity_recurring",
          cell: item => ({ "true": "Reoccurring Event" }[item.attributes.reoccurring_event.toString()] ?? "One-Time Event"),
        },
        {
          id: "signup_notify",
          header: "volunteer_opportunity_signup_notify",
          cell: item => ({ "true": "Notify of Signups" }[item.attributes.notify_admin_on_signup.toString()] ?? ""),
        },
        {
          id: "waiver_acceptance",
          header: "volunteer_opportunity_waiver",
          cell: item => ({ "required": "Required", "optional": "Optional" }[item.attributes.waiver_acceptance] ?? ""),
        },
        {
          id: "waiver_url",
          header: "volunteer_opportunity_waiver_url",
          cell: item => item.attributes.waiver_url,
        },
        {
          id: "website",
          header: "volunteer_opportunity_webaddress",
          cell: item => item.attributes.website,
        },
        {
          id: "tag_event_descriptors",
          header: "volunteer_opportunity_descriptors",
          cell: item => item.attributes.tag_sets?.event_descriptors.join("|"),
        },
        {
          id: "tag_interests",
          header: "volunteer_opportunity_interest_tags",
          cell: item => item.attributes.tag_sets?.interests.join("|"),
        },
        {
          id: "tag_skills",
          header: "volunteer_opportunity_skill_tags",
          cell: item => item.attributes.tag_sets?.skills.join("|"),
        },
        {
          id: "user_email",
          header: "volunteer_opportunity_owner_email",
          cell: item => item.attributes.user_email,
        },
        {
          id: "cause_id",
          header: "volunteer_opportunity_owner_email",
          cell: item => item.attributes.cause_id ? item.attributes.cause_id : "*Not Specified",
        }
      ]}
      stripedRows={true}
      variant="borderless"
      items={items}
      loadingText={t("bulkUpdate.loading", { filename })}
      empty={<p>{t("bulkUpdate.successes_zero")}</p>}
    />
  );
}

export function BulkUpdateDisplay({ update, filename, manyCalls }: { update: BulkUpdate | null, filename: string, manyCalls: boolean }) {
  const { t} = useTranslation();
  const navigate = useNavigate();

  // Display a loading message to the user if the update is not yet complete
  if(update === null || !update.completed ) return (
    <>
      <p>{t("bulkUpdate.loading", { filename })}</p>
      {manyCalls ? <p>{t("bulkUpdate.beforeTroubleshooting")}<Link
        href={externalLinks.bulkUpload.bulkUpdateTroubleshooting}
        external
        variant="primary"
        data-external-analytics-on="click"
        data-external-analytics-name="bulkUpdateTroubleshooting"
        data-aci-analytics-name="bulkUpdateTroubleshooting">
        {t("bulkUpdate.troubleshooting")}
        </Link>{t("bulkUpdate.afterTroubleshooting")}</p> : null}
    </>
   )

  return (<SpaceBetween direction="vertical" size="m">
    <Header info={
      <Link
        onFollow={() => navigate(`/bulk-update`)}
        variant="info"
        fontSize="body-s"
        data-external-analytics-on="click"
        data-external-analytics-name="bulkUpdateAnother"
        data-aci-analytics-name="bulkUpdateAnother">{t("bulkUpdate.uploadAnother")}</Link>}>
      {t("bulkUpdate.updateCompleteTitle")}
    </Header>
    <ExpandableSection headerText={t("bulkUpdate.failures", { count: update.result.num_failures})}>
      <BulkErrorsTable errors={update.result.failures} />
    </ExpandableSection>
    <ExpandableSection headerText={t("bulkUpdate.successes", { count: update.result.num_successes})}>
      <BulkEventsTable items={update.result.successes} />
    </ExpandableSection>
  </SpaceBetween>);
}
