// This is an example of how to call a Smithy client with pagination when we want all pages at once
// This is an anti-pattern that is only in place until our backend is improved
import { useQuery, QueryClient } from "@tanstack/react-query";
import { 
  RedVelvetApiMethod,
  RedVelvetApiMethodParameters,
  RedVelvetApiMethodOutput,
  callClientByMethodName 
} from "../api/redVelvetQueries";

export type UseAllPagesRedVelvetQueryOptions<K extends RedVelvetApiMethod, T>=  {
  enabled?:boolean,
  redVelvetQueryKey:[K, RedVelvetApiMethodParameters<K>] // Forces query key to be [methodName, methodParameters]
  converter: (output: RedVelvetApiMethodOutput<K>) => PagedResponse<T>
}
type PagedResponse<T> = {
  items:T[],
  next?:string
}

export function getAllPagesRedVelvetQueryOptions<K extends RedVelvetApiMethod,T>(options:UseAllPagesRedVelvetQueryOptions<K,T>) {
  const [methodName, methodParameters] = options.redVelvetQueryKey;

  // query all pages is used to chain all the page calls together as a single result
  // It does NOT use react-query when fetching the individual pages so that invalidating the parent
  // query automatically refreshes all the pages, since we are treating them as a single unit
  async function queryAllPages(){
    let page: string | undefined = undefined;
    let all:T[] = [];

    do {
        const result = await callClientByMethodName(methodName, { ...methodParameters, page }) as RedVelvetApiMethodOutput<K>;
        const r:PagedResponse<T> = options.converter(result);
        page = r.next;
        all = all.concat(r.items);
    } while (page !== undefined)

    return all;
  }

  return {
      enabled:options.enabled,
      queryKey:["RedVelvetApi", ...options.redVelvetQueryKey],
      queryFn:() => queryAllPages()
  }
}

// Both of the following AllPagesRedVelvetQuery functions load the pages from cached data first if it is avalible
// and call the RedVelvetApi if the data is not available in the cache.  The difference between them is that 
// useAllPagesRedVelvetQuery is intended to be used as a component hook, and generates additional information
// (such as isPending when calling the RedVelvetApi) that the component can use for rendering.
// callQueryClientAllPagesRedVelvetQuery simply provides a wrapper around the RedVelvetApi that takes the same
// inputs as the use hook, and generates response data that is identical to the useAllPagesRedVelvetQuery.data
// ie if you call useAllPagesRedVelvetQuery({ foo }) you will get { isPending: (true or false), data: ("bar" or undefined) }
// and if you call callQueryClientAllPagesRedVelvetQuery({ foo }) you will get Promise("bar")
export function useAllPagesRedVelvetQuery<K extends RedVelvetApiMethod,T>(options:UseAllPagesRedVelvetQueryOptions<K,T>) {
  return useQuery(getAllPagesRedVelvetQueryOptions(options))
}
export async function callQueryClientAllPagesRedVelvetQuery<K extends RedVelvetApiMethod,T>(queryClient: QueryClient, options:UseAllPagesRedVelvetQueryOptions<K,T>) {
  return await queryClient.ensureQueryData(getAllPagesRedVelvetQueryOptions(options));
}
