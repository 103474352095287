// Hooks
import { useMutation, useQueryClient, QueryClient } from "@tanstack/react-query";
import { getClient } from "../../utils/getClient";
// Types
import { 
    CreateAttendanceCommandInput, 
    CreateAttendanceCommandOutput,
    GetDetailedSignupsByQueryParamCommandOutput,
    GetAttendancesByQueryParamCommandOutput
} from "@amzn/red-velvet-api";

import { callQueryClientAllPagesRedVelvetQuery } from "../allPagesRedVelvetQuery"

const MUTATION_PREFIX = ["RedVelvetApi", "timeTracking"];

function getAttendanceForShiftFromDetailedSignups(queryClient: QueryClient, shiftId: string, alias: string) {
    const detailedSignups = queryClient.getQueriesData<GetDetailedSignupsByQueryParamCommandOutput>({ queryKey: ["RedVelvetApi", "getDetailedSignupsByQueryParam"]});
    const signup = detailedSignups.flatMap(([key, response]) => response?.signups || []).find(detailedSignup => 
        detailedSignup.signup.shiftId === shiftId && detailedSignup.attendance?.alias === alias);
    if(signup?.attendance) {
        return signup.attendance;
    }
    
    return undefined;
}

async function getAttendanceForShift(queryClient: QueryClient, eventId: string, shiftId: string, userAlias: string) {
    const attendances = await callQueryClientAllPagesRedVelvetQuery(queryClient, {
        redVelvetQueryKey: ["getAttendancesByQueryParam", { eventId, userAlias }],
        converter: (output: GetAttendancesByQueryParamCommandOutput) => ({ items: output.attendances || [], next: output.page })
    })
    return attendances.find(attendance => attendance.shiftId === shiftId);
}

export function useTrackTime(eventId: string, shiftId: string) {
    const client = getClient();
    const queryClient = useQueryClient();
    
    return useMutation<CreateAttendanceCommandOutput, Error, CreateAttendanceCommandInput>({
        mutationKey: MUTATION_PREFIX.concat([shiftId, "createAttendance"]),
        mutationFn: async (attendanceInput) => {
            const attendance = getAttendanceForShiftFromDetailedSignups(queryClient, shiftId, attendanceInput.alias) ||
                await getAttendanceForShift(queryClient, eventId, shiftId, attendanceInput.alias);
            
            if (attendance) {
                return await client.updateAttendance({ ...attendanceInput, ...attendance });
            }
            return await client.createAttendance({ ...attendanceInput, shiftId });
        },
    });
}
