import React, { useMemo } from "react";
import { getEvent } from "../api/events";
import { getValueFromSearchParam } from "../utils/url";
import { Event } from "../api/schemas/event";
import { EventDetails } from "../components/eventDetails";
import { FullEventDisplay } from "../components/better/fullEventDisplay";
import { useFeatures, featureIsEnabled } from "../hooks/features";
import { MoreWaysFooter } from "../components/moreWaysFooter";
import { useSearchParams } from "react-router-dom";


export function ViewEvent() {
  const [event1, setEvent] = React.useState<Event | null>(null);
  const features = useFeatures();
  const [searchParams] = useSearchParams();

  const id = useMemo(()=>getValueFromSearchParam(searchParams, "id", ""), [searchParams]);

  React.useEffect(() => {
    (async () => {
      const event = await getEvent(id);
      // Robustness for two different versions of API
      if (event.Item) {
        setEvent(JSON.parse(event.Item.data.S))
      } else {
        setEvent(event.data)
      }
    })()
  }, [id])

  if (featureIsEnabled("BenevityButBetter", features, searchParams)) {
    return <>
      <FullEventDisplay />
      <MoreWaysFooter dark/>
    </>
  }

  return (<EventDetails event={event1} />);
}
