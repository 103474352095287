import React from "react";
import Icon from "@amzn/awsui-components-react/polaris/icon";

export function CustomIcon({ name }: { name: "contact" | "location" | "skills" | "requirements"}) {
  switch (name) {
    case "contact":
      return <Icon size="medium" variant="success" svg={
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_1591_33989)">
            <path style={{strokeWidth: "1px"}} d="M19 7.5H24V9.5H19V7.5ZM17 12.5H24V14.5H17V12.5ZM20 17.5H24V19.5H20V17.5ZM2 22.5C2 18.0817 5.58172 14.5 10 14.5C14.4183 14.5 18 18.0817 18 22.5H16C16 19.1863 13.3137 16.5 10 16.5C6.68629 16.5 4 19.1863 4 22.5H2ZM10 13.5C6.685 13.5 4 10.815 4 7.5C4 4.185 6.685 1.5 10 1.5C13.315 1.5 16 4.185 16 7.5C16 10.815 13.315 13.5 10 13.5ZM10 11.5C12.21 11.5 14 9.71 14 7.5C14 5.29 12.21 3.5 10 3.5C7.79 3.5 6 5.29 6 7.5C6 9.71 7.79 11.5 10 11.5Z" fill="#037F0C"/>
          </g>
        </svg>
      }/> 
    case "location":
      return <Icon size="medium" variant="success" svg={
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_1591_33997)">
            <path style={{strokeWidth: "1px"}} d="M12 21.3995L16.9497 16.4497C19.6834 13.7161 19.6834 9.28392 16.9497 6.55025C14.2161 3.81658 9.78392 3.81658 7.05025 6.55025C4.31658 9.28392 4.31658 13.7161 7.05025 16.4497L12 21.3995ZM12 24.2279L5.63604 17.864C2.12132 14.3492 2.12132 8.65076 5.63604 5.13604C9.15076 1.62132 14.8492 1.62132 18.364 5.13604C21.8787 8.65076 21.8787 14.3492 18.364 17.864L12 24.2279ZM12 13.5C13.1046 13.5 14 12.6046 14 11.5C14 10.3954 13.1046 9.5 12 9.5C10.8954 9.5 10 10.3954 10 11.5C10 12.6046 10.8954 13.5 12 13.5ZM12 15.5C9.79086 15.5 8 13.7091 8 11.5C8 9.29086 9.79086 7.5 12 7.5C14.2091 7.5 16 9.29086 16 11.5C16 13.7091 14.2091 15.5 12 15.5Z" fill="#037F0C"/>
          </g>
        </svg>
      }/> 
    case "skills":
      return <Icon size="medium" variant="success" svg={
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path style={{strokeWidth: "1px"}} d="M5.32894 3.7706C6.56203 3.33222 7.99181 3.60651 8.97878 4.59348C10.0997 5.71439 10.3014 7.40643 9.58382 8.73287L20.2925 19.4427L18.8783 20.8569L8.16933 10.1478C6.84277 10.8659 5.1502 10.6644 4.02903 9.54323C3.04178 8.55598 2.76761 7.12567 3.20652 5.89234L5.44325 8.12902C6.02903 8.7148 6.97878 8.7148 7.56457 8.12902C8.15035 7.54323 8.15035 6.59348 7.56457 6.0077L5.32894 3.7706ZM15.6963 5.65414L18.8783 3.88638L20.2925 5.30059L18.5247 8.48257L16.757 8.83612L14.6356 10.9574L13.2214 9.54323L15.3427 7.42191L15.6963 5.65414ZM8.97878 13.7858L10.393 15.2001L5.08969 20.5034C4.69917 20.8939 4.066 20.8939 3.67548 20.5034C3.31285 20.1407 3.28695 19.5689 3.59777 19.1764L3.67548 19.0892L8.97878 13.7858Z" fill="#037F0C"/>
        </svg>
      }/> 
    case "requirements":
      return <Icon size="medium" variant="success" svg={
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path style={{strokeWidth: "1px"}} d="M20 22.5H4C3.44772 22.5 3 22.0523 3 21.5V3.5C3 2.94772 3.44772 2.5 4 2.5H20C20.5523 2.5 21 2.94772 21 3.5V21.5C21 22.0523 20.5523 22.5 20 22.5ZM19 20.5V4.5H5V20.5H19ZM8 7.5H16V9.5H8V7.5ZM8 11.5H16V13.5H8V11.5ZM8 15.5H16V17.5H8V15.5Z" fill="#037F0C"/>
        </svg>
      }/> 
  }
}