import { useEffect, useRef } from "react";
import KatalMetricsDriverSushi from "@amzn/katal-metrics-driver-sushi";
import KatalMetricsDriverConsole from '@amzn/katal-metrics/lib/driver/KatalMetricsDriverConsoleLogJson';
import { getAnalyticsConfig } from "../utils/requestConfig";
import { getAuthAlias } from "../utils/auth";
import {
    createAciPublisher,
    enableClickMonitoring,
    enableSpaPageLoadMonitoring,
    pageLoad,
    publishMetric
} from "@amzn/sparklytics-common";

export const KATAL_METRICS_CONFIG = {
    SITE: "ACI",
    SERVICE: "RedVelvet",
    DATA_ATTRIBUTE_PREFIX: "data-aci-analytics-",
    DOMAIN: "prod",
    REALM: "USAmazon",
    CAMPAIGN: "vc_campaign"
};

const DEBUG_MODE = false;

export const getKatalMetricsDriver = () => {
    return DEBUG_MODE ?
        new KatalMetricsDriverConsole() :
        new KatalMetricsDriverSushi.Builder()
            .withDomainRealm(KATAL_METRICS_CONFIG.DOMAIN, KATAL_METRICS_CONFIG.REALM)
            .build();
};

const publisherFactory = () => {
    const driver = getKatalMetricsDriver();
    const anlyticsConfig = getAnalyticsConfig();
    return createAciPublisher(driver, {
        service: `${KATAL_METRICS_CONFIG.SERVICE}_${anlyticsConfig.katalServiceNamePostfix}`,
        alias: getAuthAlias() ?? undefined,
        campaignAlias: KATAL_METRICS_CONFIG.CAMPAIGN
    })
}

/**
 * Katal Metrics initialization
 */
export const initKatalMetrics = async () => {
    enableClickMonitoring(KATAL_METRICS_CONFIG.DATA_ATTRIBUTE_PREFIX, publisherFactory);
    pageLoad(publisherFactory);
    enableSpaPageLoadMonitoring(publisherFactory);
};

export const publishKatalMetric = async (metricKey: string, metricValue: string) => {
    publishMetric(metricKey, metricValue, publisherFactory);
}

export default function KatalMetrics() {
    const initialised = useRef(false);
    useEffect(() => {
        if (!initialised.current) {
            initKatalMetrics();
            initialised.current = true;
        }
    }, []);

    return null;
}
