import React from "react";
import {EventSummary} from "../api/schemas/eventSummary";
import Container from "@amzn/awsui-components-react/polaris/container";
import Badge from "@amzn/awsui-components-react/polaris/badge";
import Link from "@amzn/awsui-components-react/polaris/link";
import {useNavigate} from "react-router-dom";
import { timeDisplayOptions } from "./eventDetails";
import {filterShiftsPerEvent} from "../utils/filterUtils";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import { externalLinks } from "../data/externalLinks";
import Button from "@amzn/awsui-components-react/polaris/button";


export const dateDisplayOptions: Intl.DateTimeFormatOptions = {
  month: "short",
  day: "numeric",
};

const displayOptions: Intl.DateTimeFormatOptions = {...dateDisplayOptions, ...timeDisplayOptions}
interface EventDisplayProps {
  event: EventSummary,
  urlSearchParams: URLSearchParams
}

enum TimeUnit {
    Minutes = 'minutes',
    Hours = 'hours',
    Days = 'days',
    Invalid = 'invalid'
}

export function getShiftLengthLabel(t: TFunction<"translation", undefined>, milliseconds: number): string {
    const shiftLengthAndUnit = getShiftLengthAndUnit(milliseconds);

    return isOngoingShift(milliseconds) ?
        t("eventDisplay.ongoingEvent") :
        t(`eventDisplay.${shiftLengthAndUnit.unit}`, { count: shiftLengthAndUnit.length });
}

export function isOngoingShift(milliseconds: number): boolean {
    return getShiftLengthAndUnit(milliseconds).unit === TimeUnit.Invalid;
}

function getShiftLengthAndUnit(milliseconds: number): {length: number, unit: TimeUnit} {
    if (Number.isNaN(milliseconds)) {
        return {length: -1, unit: TimeUnit.Invalid};
    }

    const seconds = milliseconds / 1000;
    const minutes = Math.round(seconds / 60);
    if (minutes <= 90) {
        return {length: minutes, unit: TimeUnit.Minutes};
    }

    const hours = Math.round(minutes / 60);
    if (hours <= 48) {
        return {length: hours, unit: TimeUnit.Hours};
    }

    const days = Math.round(hours / 24);
    return {length: days, unit: TimeUnit.Days}
}

export function EventDisplay({ event, urlSearchParams }: EventDisplayProps) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const filteredShifts = filterShiftsPerEvent(event, urlSearchParams);

  const displayedLocation = event.address.address_city === null ?
    t("virtualLocation") :
    t("eventDisplay.physicalLocation", { city: event.address.address_city, state: event.address.address_state });
  const locationColor = event.address.address_city === null ? "green" : "blue";

  const selectedTimezone = urlSearchParams.get("timezone");
  const urlFeatures = urlSearchParams.get("features");

  // ensures the feature flag is consistent when the page is changed in any test (build time or runtime), 
  // so we don’t have feature flags changing between pages on tests 
  // (ie first page is default and then click on an event and end up in BenevityButBetter)
  const viewDetails = () => navigate(`/view-event?id=${event.id}${selectedTimezone ? '&timezone=' + selectedTimezone : ''}${urlFeatures ? '&features=' + urlFeatures : ''}`);

  return (<Container>
    <Badge color={locationColor}>{displayedLocation}</Badge>
    <Link
      data-external-analytics-on="click"
      data-external-analytics-name="title"
      data-aci-analytics-name="title"
      onFollow={viewDetails}>
      <h2>{event.title}</h2>
    </Link>
    <SpaceBetween direction="vertical" size="xl">
      <div className="description">
        {event.cause_name ? (<div><b>Sponsored By {event.cause_name}</b></div>) : null}
        {event.description}{"\t"}
        <Link
          data-external-analytics-on="click"
          data-external-analytics-name="readMore"
          data-aci-analytics-name="readMore"
          onFollow={viewDetails}>
          {t("eventDisplay.readMore")}
        </Link>
      </div>
      <div className="shiftList">
        <SpaceBetween direction="horizontal" size="s" alignItems="center">
          {filteredShifts.map(shift =>  {
              const startDate = new Date(shift.start_timestamp || "");
              const endDate = new Date(shift.end_timestamp || "");
              const dateString = startDate.toLocaleDateString(i18n.language,
                  {...displayOptions, timeZone: selectedTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone});
              const diffTime = endDate.getTime() - startDate.getTime();
              const shiftTime = isOngoingShift(diffTime)?
                t("eventDisplay.ongoingEvent") :
                t("eventDisplay.shiftTime", { dateString, shiftLength: getShiftLengthLabel(t, diffTime)});

              return (<Button
                key={shift.shift_name}
                className="shift shiftBlurb"
                href={externalLinks.benevity.signup + `${event.id}?utm_source=${externalLinks.utm.source}&utm_medium=${'SearchPage'}&utm_campaign=${externalLinks.utm.campaign}`}
                target="_blank"
                rel="noreferrer"
                data-external-analytics-on="click"
                data-external-analytics-name="signupInBenevitySearch"
                data-external-analytics-attrs={`eventId:${event.id}`}
                data-aci-analytics-name={`signupInBenevitySearch`}>
                {shiftTime}
              </Button>)
          })}
        </SpaceBetween>
        <div className="viewMore">
          <Button
            data-external-analytics-on="click"
            data-external-analytics-name="viewAllShifts"
            data-aci-analytics-name="viewAllShifts"
            variant="link"
            onClick={viewDetails}>
            View All Shifts
          </Button>
        </div>
      </div>
    </SpaceBetween>
  </Container>);
}
