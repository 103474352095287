import { RedVelvetApi, RedVelvetApiClientConfig } from "@amzn/red-velvet-api";
import { getSmithyApiUrl } from "./requestConfig";
import { getAuthTokenOrRedirectToAuth } from "./auth";

const getToken = async (): Promise<string> => {
  return getAuthTokenOrRedirectToAuth();
};

type LimitedRequest = { request: { body: unknown; headers: Record<string, string> } };

export const getClient = (featureFlags: string[] = []): RedVelvetApi => {
  const config: RedVelvetApiClientConfig = {
    endpoint: getSmithyApiUrl(),
    token: async () => {
      return { token: await getToken() }
    },
  };
  const client = new RedVelvetApi(config);

  // For whatever reason the client forces a 'content-type' when the model
  // input has no attributes. And then for additionally unclear/annoying reasons
  // the generated server code will throw a serialization exception
  // presumably attempting to deserialize the non-existent input from the 
  // non-existent request body.
  // This code removes the "content-type" header if there is not actually
  // a body to deserialize.
  // https://issues.amazon.com/issues/cce-3555
  client.middlewareStack.add((next, context) => (args: unknown) => {
    if (isRequest(args)) {
      if (!args.request.body) {
        delete args.request.headers['content-type'];
      }
      if (featureFlags.length > 0) {
        args.request.headers['x-amz-vc-enabled-feature-flags'] = JSON.stringify(featureFlags)
      }
    }
    // I could not find good examples of doing this in a strongly typed way so I give up.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return next(args as any);
  },
    {
      step: "finalizeRequest",
    })
  return client;
}

function isRequest(args: unknown): args is LimitedRequest {
  const isit = args as LimitedRequest;

  if (!("request" in isit)) {
    return false;
  }

  if (!("headers" in isit.request)) {
    return false;
  }
  return true
}