import React from "react";
import { DateRangePicker, DateRangePickerProps } from "@amzn/awsui-components-react";
import {useTranslation} from "react-i18next";
import { TFunction } from "i18next";

export interface AbsoluteDatePickerProps {
  onChange: (value: DateRangePickerProps.Value | null) => void;
  value: DateRangePickerProps.Value | null;
}

// Visible for testing
export function isValidRange(t: TFunction<"translation", undefined>, range: DateRangePickerProps.Value | null): DateRangePickerProps.ValidationResult {
  if (range && range.type === "absolute") {
    const [ startDateWithoutTime ] = range.startDate.split("T");
    const [ endDateWithoutTime ] = range.endDate.split("T");
    if ( !startDateWithoutTime || !endDateWithoutTime) {
      return {
        valid: false,
        errorMessage: t("absoluteDatePicker.incomplete")
      };
    }

    if ((new Date(range.startDate)).getTime() > (new Date(range.endDate)).getTime()) {
      return {
        valid: false,
        errorMessage: t("absoluteDatePicker.invalid")
      };
    }
  }
  return { valid: true };
}

export function AbsoluteDatePicker (props: AbsoluteDatePickerProps) {
  const { t } = useTranslation();

  return (
    <DateRangePicker
      onChange={({ detail }) => props.onChange(detail.value)}
      value={props.value}
      relativeOptions={[]}
      isValidRange={range => isValidRange(t, range)}
      i18nStrings={t('absoluteDatePicker', { returnObjects: true }) as DateRangePickerProps.I18nStrings}
      placeholder={t('absoluteDatePicker.placeholder')}
      dateOnly
      rangeSelectorMode="absolute-only"
      expandToViewport
    />
  );
}