import { useEffect, useRef } from "react";
import { Analytics, Auth } from "aws-amplify";
import { getAnalyticsConfig } from "../utils/requestConfig";
import { AnalyticsConfig } from "../config";
import { getSessionAttributes, trackSessions } from "../lib/analytics";
import { CookieHelper } from "../utils/cookieHelper";


const initAuth = (analyticsConfig: AnalyticsConfig) => {
  const authConfig = {
    Auth: {
      identityPoolId: analyticsConfig.identityPoolId,
      region: analyticsConfig.region
    }
  };

  Auth.configure(authConfig);
};

const initAnalytics = (analyticsConfig: AnalyticsConfig) => {
  const config = {
    AWSPinpoint: {
      appId: analyticsConfig.appId,
      region: analyticsConfig.region,
      mandatorySignIn: false,
    }
  };

  Analytics.configure(config);
};

/**
 * A mechanism that allows test browsers to visit the website without breaking
 * metrics (i.e. in the case of canary)
 */
const getTestTrafficFlag = (): string => {
  const cookies = new CookieHelper();
  const service_user = cookies.get('service_user')
  // todo: on confirming test changes succeed, remove localStorage piece per SIM:
  //  https://issues.amazon.com/issues/cce-2845

  if (service_user || (window.localStorage.getItem("vc_test_traffic") === "true")) {
    return "true";
  }
  return "false";
}


const getCampaignTag = (): string => {
  const params = new URLSearchParams(window.location.search);
  const campaign = params.get("vc_campaign");
  if (campaign != null) {
    return campaign;
  }
  return "";
};

const getStandardAttributes = (): Record<string, string> => {
  // it is somewhat wasteful to repeat all this information for all events
  // but it is the most straightforward way to efficiently query for
  // campaign/test traffic/session/etc. on the back end.
  return Object.assign({
    vc_test_traffic: getTestTrafficFlag(),
    campaign: getCampaignTag(),
  }, getSessionAttributes());
}

const initSessionTrackEvents = () => {
  /**
   * track our own custom session attributes. See https://issues.amazon.com/issues/cce-2779
   */
  trackSessions({
    attributes: getStandardAttributes
  });

  Analytics.autoTrack('session', {
    enable: true,
    attributes: getStandardAttributes
  });

}

const initAutoTrackEvents = () => {
  //you MUST enable session track events before pageView or some pageView events
  //will occur outside of the session
  initSessionTrackEvents();

  Analytics.autoTrack('pageView', {
    enable: true,
    type: 'SPA',
    attributes: getStandardAttributes
  });

  // Auto Track external links
  // This allows us to add the data-external-analytics- to elements to track
  // how users are moving off of the site (ie to Benevity or other external sites)
  Analytics.autoTrack('event', {
    enable: true,
    events: ['click'],
    selectorPrefix: 'data-external-analytics-',
    attributes: getStandardAttributes
  });
};

export const initMetrics = () => {
  const analyticsConfig = getAnalyticsConfig();

  if (analyticsConfig) {
    initAuth(analyticsConfig);
    initAnalytics(analyticsConfig);
    initAutoTrackEvents();
  }
};

export const publishMetric = (name: string) => {
  const analyticsConfig = getAnalyticsConfig();
  if (analyticsConfig) {
    Analytics.record({
      name: name,
      attributes: getStandardAttributes()
    });
  }
};
export const publishMetricsWithAttributes = (name: string, attributeKey: string, attributeValue: string) => {
  const analyticsConfig = getAnalyticsConfig();
  if (analyticsConfig) {
    Analytics.record({
      name: name,
      attributes: Object.assign(getStandardAttributes(), {
        [attributeKey]: attributeValue.toString(),
        value: attributeValue.toString() // Allows all filters to have values in the same bucket, simplifying analytics
      })
    });
  }
};

/**
 * If a browser uses the ?canary option. Local storage will
 *
 */
// todo: on confirming test changes succeed, remove this per SIM: https://issues.amazon.com/issues/cce-2845
const handleTestTrafficFlag = () => {
  const params = new URLSearchParams(window.location.search);
  if (params.get("_test_traffic") != null) {
    window.localStorage.setItem("vc_test_traffic", "true");
  }
}

export default function PinpointMetrics() {
  const initialised = useRef(false);
  useEffect(() => {
    handleTestTrafficFlag();
    if (!initialised.current) {
      initMetrics();
      initialised.current = true;
    }
  }, []);

  return null;
}
