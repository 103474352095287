// GOLDEN PATH CODE
// An example of related mutations that can be used across the code base
// This code provides the mutations and the mutation state that can be used
// to track the information in multiple places
// Based on https://tanstack.com/query/latest/docs/framework/react/reference/useMutationState

// Hooks
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useMutationState } from "@tanstack/react-query";
import { getClient } from "../../utils/getClient";
// Types
import { MutationStatus, Mutation } from "@tanstack/react-query";
import { 
    CreateSignupCommandInput, 
    CreateSignupCommandOutput, 
    DeleteSignupCommandInput, 
    DeleteSignupCommandOutput, 
    Signup,
    Shift
} from "@amzn/red-velvet-api";
import { AlertInfo } from "../betterEvents/reducer";
// Utils
import { asDateString, asTimeString } from "../../utils/dateFormatting";

const MUTATION_PREFIX = ["RedVelvetApi", "shiftSignup"]

export type CreateSignupInput = {
    eventId: string,
    shift: Shift,
    request: CreateSignupCommandInput
}

export function useCreateSignup(shiftId: string) {
    const client = getClient();
    return useMutation<CreateSignupCommandOutput, string, CreateSignupInput>({
        // GOLDEN PATH NOTE
        // Mutation keys have a different order than GET commands, since the goal is to move from most specific to
        // least specific key.  In this case, the same shift might have both create and delete mutations
        mutationKey: MUTATION_PREFIX.concat([shiftId, "createSignup"]),
        // GOLDEN PATH NOTE
        // Here we use the data sent by the user to create the mutation, but we enforce the shiftId
        // for signup, since we are using that in the mutation key
        mutationFn: (signupInput) =>  client.createSignup({ ...signupInput.request, shift: shiftId }),
    });
}

export type DeleteSignupInput = {
    eventId: string,
    shift: Shift,
    request: DeleteSignupCommandInput
}

export function useDeleteSignup(shiftId: string) {
    const client = getClient();
    return useMutation<DeleteSignupCommandOutput, string, DeleteSignupInput>({
        mutationKey: MUTATION_PREFIX.concat([shiftId, "deleteSignup"]),
        mutationFn: (signupInput) =>  client.deleteSignup(signupInput.request)
    });
}

export type SignupMutationData = { 
    status: MutationStatus,
    variables: CreateSignupInput | DeleteSignupInput,
    timestamp: number,
    error: Error | null,
    data: CreateSignupCommandOutput | DeleteSignupCommandOutput | null
}

function flattenSignupMutation(mutation: Mutation) {
    return {
        status: mutation.state.status,
        // GOLDEN PATH NOTE
        // This cast is safe because we are filtering on the mutation key
        // However react-query has no way to know the input shape
        variables: mutation.state.variables as CreateSignupInput | DeleteSignupInput,
        error: mutation.state.error,
        timestamp: mutation.state.submittedAt,
        data: mutation.state.data as CreateSignupCommandOutput | DeleteSignupCommandOutput | null,
    }
}

function requestIsDelete(request: CreateSignupCommandInput | DeleteSignupCommandInput): request is DeleteSignupCommandInput {
    return "signupId" in request;
}

function useExtractAlert(data: SignupMutationData[], dismissedTimestamp: number): AlertInfo | undefined {
    const { t, i18n } = useTranslation();

    if(data.length === 0) return undefined;

    const lastUpdate = data[data.length - 1];

    if(lastUpdate.timestamp < dismissedTimestamp) return undefined;

    if(lastUpdate.status === 'success') {
        const shift = lastUpdate.variables.shift
        
        // GOLDEN PATH NOTE
        // Since we want to alert on both create and delete signups
        // We need to differentiate between them
        // Luckily only delete signup has a signup id
        if(requestIsDelete(lastUpdate.variables.request)) {
            return {
                type: "success",
                message: t("useEvents.signupCancelSuccess")
            }
        }
        
        return {
            type: "success",
            message: shift.startDateTime ? 
                t("useEvents.signupSuccess", {
                    shiftName: shift.name,
                    shiftDate: asDateString(shift.startDateTime, undefined, i18n.language),
                    shiftTime: asTimeString(shift.startDateTime, undefined, i18n.language)
                }) : 
                t("useEvents.ongoingSignupSuccess", { shiftName: shift.name })
        }
    }
    
    if(lastUpdate.status === 'error') {
        const shift = lastUpdate.variables.shift
        return {
            type: "error",
            message: t("useEvents.signupError", {
                shiftName: shift.name,
                reason: `${lastUpdate.error}`
            })
        }
    }
}

export function useSignupAlerts(shiftId: string | undefined = undefined) {
    // GOLDEN PATH NOTE
    // By marking the last dismissed timestamp and filtering out any update events
    // before that timestamp, we can ensure that users never see an alert that they 
    // already dismissed
    const [dismissedTimestamp, setDismissedTimestamp] = useState(0);
    const data = useMutationState({
        filters: {
            mutationKey: !shiftId ? MUTATION_PREFIX : [...MUTATION_PREFIX, shiftId],
            // GOLDEN PATH NOTE
            // Alerts don't care about pending or idle mutations
            predicate: (mutation) => mutation.state.status === 'success' ||  mutation.state.status === 'error'
        },
        select: flattenSignupMutation,
    })

    return {
        alert: useExtractAlert(data, dismissedTimestamp),
        onDismiss: () => setDismissedTimestamp(Date.now())
    }
}

// GOLDEN PATH NOTE
// Curently using this hook to determine the latest sigup/unsigned up state for a particular shift
// This checks if the most recent mutation is currently loading, and also whether the lats successfull 
// mutation is a signup or a delete, and returns the most recently successful signup if relevent, or the
// signup returned by the server if not relevant
// Technically this is an anti-pattern; it would be more ideal to simple update the cached data for react-query
// however, currently doing that breaks accessibility in our UI
export function useLatestSignupMutationState(shiftId: string, signup: Signup | undefined): { signup: Signup | undefined, loading: boolean } {
    const data = useMutationState({
        filters: {
            mutationKey: MUTATION_PREFIX.concat([shiftId]),
        },
        select: flattenSignupMutation,
    })
    
    const loading = data.length !== 0  && (data[data.length - 1].status === 'pending' || data[data.length - 1].status === 'idle');
    
    // GOLDEN PATH NOTE
    // We only care about the most recently successful mutation
    // Since users can toggle the signup back and forth
    let lastIndex = data.length - 1;
    while (lastIndex >= 0) {
        const lastUpdate = data[lastIndex];
        if(lastUpdate.status === 'success') {
            const shift = lastUpdate.variables.shift;
            return { 
                signup: requestIsDelete(lastUpdate.variables.request) ? 
                    undefined :
                    { 
                        signupId: (lastUpdate.data as CreateSignupCommandOutput).signupId,
                        shiftId: shift.shiftId,
                        userId: "", // This value is wrong, but it is not actually used anywhere
                        eventId: shift.eventId,
                        startDateTime: shift.startDateTime || new Date(lastUpdate.timestamp)
                    }, 
                loading 
            }
        }
        lastIndex--;
    }
    
    // If we did not find a successful mutation, base the status on 
    // the existence of a related signup
    return { signup: signup, loading }
}
