import { Cards, ColumnLayout, Icon, Link, Spinner, TextContent } from "@amzn/awsui-components-react";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import { useEffect, useState } from "react";
import { Event } from "../api/schemas/event";
import { Shift } from "../api/schemas/shift";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import Badge from "@amzn/awsui-components-react/polaris/badge";
import { isOngoingShift } from "./eventDisplay";
import { useCollection } from '@amzn/awsui-collection-hooks';
import { Pagination } from '@amzn/awsui-components-react';
import { filterShiftsPerEvent } from "../utils/filterUtils";
import { ShiftDisplay } from "./shiftDisplay"

import { publishMetricsWithAttributes } from "./analytics";
import { publishKatalMetric } from "./katalAnalytics";

export const dateDisplayOptions: Intl.DateTimeFormatOptions = {
  month: "long",
  day: "numeric",
  year: "numeric"
};

export const timeDisplayOptions: Intl.DateTimeFormatOptions = {
  timeZoneName: "shortGeneric",
  hour: "numeric",
  minute: "numeric"
}

function Contact({ name, email, phone }: { name: string, email?: string, phone?: string }) {
  const { t } = useTranslation();

  return (<TextContent>
    <h4>{name}</h4>
    {email ? (<p>{t("eventDetails.contact.email")}:
      <a
        data-external-analytics-on="click"
        data-external-analytics-name="contactEmail"
        data-aci-analytics-name="contactEmail"
        href={`mailto:${email}`}>{email}</a></p>) :
      null}
    {phone ? (<p>{t("eventDetails.contact.phone")}:
      <a
        data-external-analytics-on="click"
        data-external-analytics-name="contactPhone"
        data-aci-analytics-name="contactPhone"
        href={`tel:${phone}`}>{phone}</a></p>) : null}
  </TextContent>)
}



function determineDisplayItemsCount() {
  if (typeof window === "undefined") return 1;
  const windowWidth: number = window.innerWidth;

  return Math.floor(windowWidth / 300) || 1;
}

function useDisplayShiftsCount() {
  const [displayItemsCount, setDisplayItemsCount] = useState<number>(determineDisplayItemsCount());

  useEffect(() => {
    function handleResize() {
      const count: number = determineDisplayItemsCount();
      if (count !== displayItemsCount)
        setDisplayItemsCount(count);
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [displayItemsCount]);

  return displayItemsCount;
}

export function EventDetails({ event }: { event: Event | null }) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [searchParams,] = useSearchParams();
  const shifts = event ? filterShiftsPerEvent(event, searchParams) : []
  const shiftsPerPage = useDisplayShiftsCount();

  const {
    items,
    collectionProps,
    paginationProps
  } = useCollection(
    shifts,
    {
      filtering: {},
      pagination: { pageSize: shiftsPerPage },
      sorting: {},
      selection: {},
    }
  );

  const selectedTimezone = searchParams.get("timezone") || Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (!event) {
    return <Container
      header={
        <Header>
          <h1>{t("eventDetails.loadingEvent")}</h1>
        </Header>}>
      <Spinner />
    </Container>
  }

  // Fallback case if event does not exist
  if (!event.id) {
    return <Container
      header={
        <Header>
          <h1>{t("eventDetails.noEvent")}</h1>
        </Header>}>
      {t("eventDetails.404")}
    </Container>
  }

  const displayedLocation = event.address.address_city === null ?
    t("virtualLocation") :
    t("eventDisplay.physicalLocation", { city: event.address.address_city, state: event.address.address_state });
  const locationColor = event.address.address_city === null ? "green" : "blue";

  const goBack = () => {
    // If the user navigated here from search, we want to return to the search they were previously using so we maintain
    // any filters they were using
    if (window.history.length > 1) navigate(-1);
    // However, if the user simply pasted a link into the browser, we don't have any filters to load and should just go
    // to the normal search
    else navigate('/');
  }

  const startDate = new Date(event.start_timestamp || "");
  const endDate = new Date(event.end_timestamp || "");
  const localeStartDateString = startDate.toLocaleDateString(i18n.language, { ...dateDisplayOptions, timeZone: selectedTimezone });
  const localeEndDateString = endDate.toLocaleDateString(i18n.language, { ...dateDisplayOptions, timeZone: selectedTimezone });
  const timeDiff = endDate.getTime() - startDate.getTime();
  const date = isOngoingShift(timeDiff) ? t("eventDisplay.ongoingEvent") : localeStartDateString + " - " + localeEndDateString;
  const location = event.address.address_city ? " ⋅ " + event.address.address_city + ", " + event.address.address_state : ""
  const description = date + location;
  const shiftsHeader = shifts.length > 0 ? "eventDetails.shifts" : "eventDetails.noShifts"

  return (
    <div>
      <Container>
        <p>
          <Link
            data-external-analytics-on="click"
            data-external-analytics-name="backToSearch"
            data-aci-analytics-name="backToSearch"
            href="/"
            onFollow={goBack}>
            {t("eventDetails.goBack")}
          </Link>
        </p>
        <Badge color={locationColor}>{displayedLocation}</Badge>
        <Header variant="h1" description={event.cause_name ? description : null}>
          {event.title}
        </Header>
        <p>{event.attributes.summary}</p>
      </Container>
      <div className="shiftSection">
        <Header>
          {t(shiftsHeader)}
        </Header>
        <Cards
          {...collectionProps}
          cardDefinition={{
            header: (shift: Shift) => {
              return (<ShiftDisplay
                key={shift.shift_name}
                event={event}
                shift={shift}
                selectedTimezone={selectedTimezone} />)
            }
          }}
          items={items}
          cardsPerRow={[{ cards: shiftsPerPage }]}
        />
        <Header actions={<Pagination
          {...paginationProps}
          onChange={(event) => {
            publishMetricsWithAttributes("shiftPage", "page", event.detail.currentPageIndex.toString());
            publishKatalMetric("shiftPage", event.detail.currentPageIndex.toString());
            paginationProps.onChange(event);
          }}
          ariaLabels={{
            nextPageLabel: t("eventList.topPaginationControl.next"),
            previousPageLabel: t("eventList.topPaginationControl.previous"),
            paginationLabel: t("eventList.topPaginationControl.label")
          }} />} />
      </div>
      <Container>
        <Grid gridDefinition={[{ colspan: { default: 12, xs: 9 } }, { colspan: { default: 12, xs: 3 } }]}>
          <SpaceBetween direction="vertical" size="s">
            <div className="description">
              <h2>{t("eventDetails.about")}</h2>
              {event ? (<p dangerouslySetInnerHTML={{ __html: event.attributes.description }} />) : (<Spinner />)}
              <img
                src={event.attributes.hero_image?.urls.large || ""}
                alt={event.attributes.hero_image?.title || ""} />
              {/* Note: the image description is visible below the image to provide accessibility for low vision or
              neurodivergent users, but it is redundant to screenreaders as it is also set as the image's alt text,
              so we hide it using an aria label so screenreaders don't read it twice*/}
              <sub aria-hidden="true">{event.attributes.hero_image?.title ? event.attributes.hero_image?.title : t("eventDetails.noImgDesc")}</sub>
            </div>
          </SpaceBetween>
          <div className="sidebar">
            <ColumnLayout borders="horizontal" columns={1}>
              <Header variant="h2">Details</Header>
              <div>
                <h3><Icon name="call" size="medium" variant="success" /> {t("eventDetails.primaryContact")}</h3>
                <div className="sidebarContent">
                  {event ? (<Contact
                    name={event.attributes.contacts.primary.name}
                    email={event.attributes.contacts.primary.email}
                    phone={event.attributes.contacts.primary.phone} />) : (<Spinner />)}
                </div>
              </div>
              <div>
                <h3><Icon name="suggestions" size="medium" variant="success" /> {t("eventDetails.location")}</h3>
                <div className="sidebarContent">
                  {event.address.address_state ?
                    <div>
                      <div>{event.address.address_name}</div>
                      <div>{event.address.address_street}</div>
                      <div>{event.address.address_city}, {event.address.address_state} {event.address.address_postcode}</div>
                    </div> : <p>{t("virtualLocation")}</p>}
                </div>
              </div>
              <div>
                <h3><Icon name="multiscreen" size="medium" variant="success" /> {t("eventDetails.skills")}</h3>
                <div className="sidebarContent">
                  {event.skills ? event.skills.map(skill => <p key={skill} >{skill}</p>) : <p>{t("eventDetails.noSkills")}</p>}
                </div>
              </div>
              <div>
                <h3><Icon name="security" size="medium" variant="success" /> {t("eventDetails.requirements")}</h3>
                <div className="sidebarContent">
                  {
                    event.attributes.waiver_acceptance !== "none" ?
                      <p>{t("eventDetails.waiverRequired")}</p> :
                      <p>{t("eventDetails.noRequirements")}</p>
                  }
                </div>
              </div>
            </ColumnLayout>
          </div>
        </Grid>
      </Container>
    </div>
  );
}
