import {getApiUrl, getSiteUrl} from '../utils/requestConfig';
import { NotFoundError, PermissionsError, RequestError } from '../utils/errorHelper';
import { getAuthTokenOrRedirectToAuth } from '../utils/auth';

const getToken = async (): Promise<string> => {
  return getAuthTokenOrRedirectToAuth();
};

const makeRequest = async (url: string, options: Partial<RequestInit>) => {
  const endpoint = `${getApiUrl()}${url}`;
  let fetchParams = {};
  fetchParams = {
    ...options,
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${await getToken()}`,
      ...(options.headers || {}),
    },
  };

  return window.fetch(endpoint, fetchParams).then(res => {
    if (res.ok) {
      return res;
    }

    return res.json().then(json => {
      if (res.status === 403 && json && json.permissionGroups) {
        throw new PermissionsError();
      }
      if (res.status === 404) {
        throw new NotFoundError(`Resource not found: ${url}`);
      } else {
        throw new RequestError(`${res.status}`, endpoint, options.method ?? "NOT SPECIFIED");
      }
    });
  });
};

const requestJson = async (url: string, options:Partial<RequestInit> = {}) => {
  if (getApiUrl() === "local") {
    return fetch(getSiteUrl() + "/local" + url.split("?")[0] + ".json").then(res => res.json());
  }

  return makeRequest(url, {
    ...options,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      ...(options.headers || {}),
    },
  }).then(res => res.json());
}


export const getJson = (url: string, options = {}) => requestJson(url, { ...options, method: 'GET' });

export const postJson = (url: string , data = {}, options = {}) =>
  requestJson(url, { ...options, method: 'POST', body: JSON.stringify(data) });