import React from "react";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import { getOptionFromValue } from "../utils/url";
import { Input } from "@amzn/awsui-components-react";
import Select from "@amzn/awsui-components-react/polaris/select";
import { locationOptions } from "../data/locations";
import { useTranslation } from "react-i18next";
import { useSharedFilters } from "../hooks/filters";
import Grid from "@amzn/awsui-components-react/polaris/grid";

export function HighlightedSearch () {
  const {
    filters,
    updaters,
    setValueWithDebounce,
    setValueWithoutDebounce
  } = useSharedFilters()

  const { t } = useTranslation();

  return (
    <div className="highlightedSearch">
      <FormField label={t("advancedSearch.blurb")} stretch>
        <Grid gridDefinition={[{ colspan: { default: 12, "xs": 6 } }, { colspan: { default: 12, "xs": 3 } }, { colspan: { default: 12, "xs": 3 } }]}>
          <Input
            onChange={({ detail }) => setValueWithDebounce("query", detail.value, updaters.setQuery, true)}
            value={filters.query}
            placeholder={t("advancedSearch.keyword")}
            ariaLabel={t("advancedSearch.keyword")}/>
          <Select
            onChange={({ detail }) => setValueWithoutDebounce("volunteer_location", detail.selectedOption.value || "", true)}
            selectedOption={getOptionFromValue(filters.area, locationOptions)}
            options={locationOptions}
            ariaLabel={t("advancedSearch.location")}
            placeholder={t("advancedSearch.locationPlaceholder")}/>
          <Input
              onChange={({ detail }) => setValueWithDebounce("spots", detail.value, updaters.setSpots, true)}
              type="number"
              value={filters.spots}
              placeholder={t("advancedSearch.openSpots")}
              ariaLabel={t("advancedSearch.openSpots")}/>
        </Grid>
      </FormField>
    </div>
  );
}
