import { useEffect } from "react";

export function useUnsavedChangesBrowserNavigationPrompt(hasChanges: boolean) {
  // In addition to any links on the page, users can navigate away from the page by changing the url in the browser or closing the tab
  // This useEffect hook ensures that the browser native modal for data loss will display when a user attempts to navigate away without using in-page links
  // We cannot display the modal here, because the native browser modal for confirming exit will always display when a beforeunload event is canceled
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if(hasChanges) {
        // GOLDEN PATH NOTE
        // If desired, save data that will be lost in local storage so it can be restored later

        event.preventDefault();
        event.returnValue = ''; // This is needed so that some browsers such as chromium-based browsers will handle the canceled event properly
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasChanges]);
}