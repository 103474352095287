import Link from "@amzn/awsui-components-react/polaris/link";
import { ColumnMap } from '../../hooks/tableColumns';
import { Person } from "@amzn/red-velvet-api";
import { PropertyFilterProps } from "@amzn/awsui-components-react/polaris/property-filter";

// This is pulled from the PAPI params size in https://code.amazon.com/packages/RedVelvetApiTypescriptLambda/blobs/mainline/--/src/clients/papiClient.ts
export const PEOPLE_PAGE_SIZE = 25;

export interface Amazonian {
  name: string,
  alias?: string,
  managerLogin?: string,
  building?: string
}

// GOLDEN PATH NOTE
// Tables require several properties to define columns that can cause a large amount of repetitive copy paste
// Whenever possible, table columns should be defined as a map across the different data keys, to prevent user errors in the copy paste
// The ColumnMap type and associated useTableColumns hook perform the most common mapping for tables
export const columnMap: ColumnMap<Amazonian> = {
  name: (item: Amazonian) => item.name,
  alias: (item: Amazonian) => <Link href={`https://phonetool.amazon.com/users/${item.alias}`}>{item.alias}</Link>,
  managerLogin: (item: Amazonian) => <Link target="_blank" href={`https://phonetool.amazon.com/users/${item.managerLogin}`}>{item.managerLogin}</Link>,
  building: (item: Amazonian) => item.building || "",
}

export function getAliasesFromSelected(attendees: Amazonian[]): string[] {
  return attendees.map(attendee => attendee.alias).filter((alias): alias is string => alias !== undefined);
}

export function getAmazoniansFromPeople(people?: Person[]): Amazonian[] {
	return (people || [])
    	.map(person => ({ ...person, name: `${person.lastName}, ${person.firstName}`}));
}

export function convertTokensToPeopleQuery(filters: PropertyFilterProps.Query) {
  const people: string[] = [];
  const managers: string[] = [];
  const buildings: string[] = [];
  const countries: string[] = [];
  const aliases: string[] = [];
  const levels: string[] = [];

  filters.tokens.forEach(token => {
    switch(token.propertyKey) {
      case "manager": 
        managers.push(token.value);
        break;
      case "building": 
        buildings.push(token.value);
        break;
      case "country": 
        countries.push(token.value);
        break;
      case "alias": 
        aliases.push(token.value);
        break;
      case "level": 
        levels.push(token.value);
        break;
      default:
        people.push(token.value);
        break;
    }
  })
  return {
    people: people.length > 0 ? people.join(",") : undefined,
    managers: managers.length > 0 ? managers.join(",") : undefined,
    buildings: buildings.length > 0 ? buildings.join(",") : undefined,
    countries: countries.length > 0 ? countries.join(",") : undefined,
    aliases: aliases.length > 0 ? aliases.join(",") : undefined,
    levels: levels.length > 0 ? levels.join(",") : undefined
  }
}
