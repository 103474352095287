import moment from "moment-timezone";

interface Timezone {
    text: string;
    id: string;
}

const timezoneOptionsCache: Timezone[] = [];

// We need to maintain a list of timezones since there's no built-in timezone list and no other available library
// that provides a short yet sufficient list of timezones.
// Reference: https://github.com/ndom91/react-timezone-select/blob/main/src/timezone-list.ts
const allTimezoneOptions: Timezone[] = [
    {text: "Midway Island, Samoa", id: "Pacific/Midway"},
    {text: "Hawaii", id: "Pacific/Honolulu"},
    {text: "Alaska", id: "America/Juneau"},
    {text: "Mountain Time", id: "America/Boise"},
    {text: "Dawson, Yukon", id: "America/Dawson"},
    {text: "Chihuahua, La Paz, Mazatlan", id: "America/Chihuahua"},
    {text: "Arizona", id: "America/Phoenix"},
    {text: "Central Time", id: "America/Chicago"},
    {text: "Saskatchewan", id: "America/Regina"},
    {text: "Guadalajara, Mexico City, Monterrey", id: "America/Mexico_City"},
    {text: "Central America", id: "America/Belize"},
    {text: "Eastern Time", id: "America/Detroit"},
    {text: "Bogota, Lima, Quito", id: "America/Bogota"},
    {text: "Caracas, La Paz", id: "America/Caracas"},
    {text: "Santiago", id: "America/Santiago"},
    {text: "Newfoundland and Labrador", id: "America/St_Johns"},
    {text: "Brasilia", id: "America/Sao_Paulo"},
    {text: "Tijuana", id: "America/Tijuana"},
    {text: "Montevideo", id: "America/Montevideo"},
    {text: "Buenos Aires, Georgetown", id: "America/Argentina/Buenos_Aires"},
    {text: "Greenland", id: "America/Godthab"},
    {text: "Pacific Time", id: "America/Los_Angeles"},
    {text: "Azores", id: "Atlantic/Azores"},
    {text: "Cape Verde Islands", id: "Atlantic/Cape_Verde"},
    {text: "UTC", id: "UTC"},
    {text: "Edinburgh, London", id: "Europe/London"},
    {text: "Dublin", id: "Europe/Dublin"},
    {text: "Lisbon", id: "Europe/Lisbon"},
    {text: "Casablanca, Monrovia", id: "Africa/Casablanca"},
    {text: "Canary Islands", id: "Atlantic/Canary"},
    {text: "Belgrade, Bratislava, Budapest, Ljubljana, Prague", id: "Europe/Belgrade"},
    {text: "Sarajevo, Skopje, Warsaw, Zagreb", id: "Europe/Sarajevo"},
    {text: "Brussels, Copenhagen, Madrid, Paris", id: "Europe/Brussels"},
    {text: "Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna", id: "Europe/Amsterdam"},
    {text: "West Central Africa", id: "Africa/Algiers"},
    {text: "Bucharest", id: "Europe/Bucharest"},
    {text: "Cairo", id: "Africa/Cairo"},
    {text: "Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius", id: "Europe/Helsinki"},
    {text: "Athens", id: "Europe/Athens"},
    {text: "Jerusalem", id: "Asia/Jerusalem"},
    {text: "Harare, Pretoria", id: "Africa/Harare"},
    {text: "Istanbul, Minsk, Moscow, St. Petersburg, Volgograd", id: "Europe/Moscow"},
    {text: "Kuwait, Riyadh", id: "Asia/Kuwait"},
    {text: "Nairobi", id: "Africa/Nairobi"},
    {text: "Baghdad", id: "Asia/Baghdad"},
    {text: "Tehran", id: "Asia/Tehran"},
    {text: "Abu Dhabi, Muscat", id: "Asia/Dubai"},
    {text: "Baku, Tbilisi, Yerevan", id: "Asia/Baku"},
    {text: "Kabul", id: "Asia/Kabul"},
    {text: "Ekaterinburg", id: "Asia/Yekaterinburg"},
    {text: "Islamabad, Karachi, Tashkent", id: "Asia/Karachi"},
    {text: "Chennai, Kolkata, Mumbai, New Delhi", id: "Asia/Kolkata"},
    {text: "Kathmandu", id: "Asia/Kathmandu"},
    {text: "Astana, Dhaka", id: "Asia/Dhaka"},
    {text: "Sri Jayawardenepura", id: "Asia/Colombo"},
    {text: "Almaty, Novosibirsk", id: "Asia/Almaty"},
    {text: "Yangon Rangoon", id: "Asia/Rangoon"},
    {text: "Bangkok, Hanoi, Jakarta", id: "Asia/Bangkok"},
    {text: "Krasnoyarsk", id: "Asia/Krasnoyarsk"},
    {text: "Beijing, Chongqing, Hong Kong SAR, Urumqi", id: "Asia/Shanghai"},
    {text: "Kuala Lumpur, Singapore", id: "Asia/Kuala_Lumpur"},
    {text: "Taipei", id: "Asia/Taipei"},
    {text: "Perth", id: "Australia/Perth"},
    {text: "Irkutsk, Ulaanbaatar", id: "Asia/Irkutsk"},
    {text: "Seoul", id: "Asia/Seoul"},
    {text: "Osaka, Sapporo, Tokyo", id: "Asia/Tokyo"},
    {text: "Yakutsk", id: "Asia/Yakutsk"},
    {text: "Darwin", id: "Australia/Darwin"},
    {text: "Adelaide", id: "Australia/Adelaide"},
    {text: "Canberra, Melbourne, Sydney", id: "Australia/Sydney"},
    {text: "Brisbane", id: "Australia/Brisbane"},
    {text: "Hobart", id: "Australia/Hobart"},
    {text: "Vladivostok", id: "Asia/Vladivostok"},
    {text: "Guam, Port Moresby", id: "Pacific/Guam"},
    {text: "Magadan, Solomon Islands, New Caledonia", id: "Asia/Magadan"},
    {text: "Kamchatka, Marshall Islands", id: "Asia/Kamchatka"},
    {text: "Fiji Islands", id: "Pacific/Fiji"},
    {text: "Auckland, Wellington", id: "Pacific/Auckland"},
    {text: "Nuku'alofa", id: "Pacific/Tongatapu"},
]

function calculateOffsets() {
    allTimezoneOptions.forEach((timezone) => {
        const offset = moment.tz(timezone.id).format('Z');
        timezoneOptionsCache.push({text: `GMT${offset} ${timezone.text}`, id: timezone.id})
    })
}

function getTimezoneOptions() {
    if(timezoneOptionsCache.length === 0) {
        calculateOffsets();
    }
    return timezoneOptionsCache;
}

export const allTimezoneWithOffsetOptions = getTimezoneOptions();
