import { useTranslation } from "react-i18next";
import { PropertyFilterProps } from "@amzn/awsui-components-react/polaris/property-filter";

// These functions provide a default pattern for i18n strings from Cloudscape components
// Default values are stored in src/localization/{lng}.json, but can be overriden by specific prefixes or namespaces
// ONLY override the SPECIFIC strings that need to change, and leave the strings where you wish to use the defaults as undefined in the prefix

export function usePropertyFilterStrings(filterPrefix: string, namespaces: string[] = ['translation']): PropertyFilterProps.I18nStrings {
  const { t } = useTranslation(namespaces);

  return {
          enteredTextLabel: (text) => t([`${filterPrefix}.enteredTextLabel`, `propertyFilter.enteredTextLabel`], { text }),
          allPropertiesLabel: t([`${filterPrefix}.allPropertiesLabel`, `propertyFilter.allPropertiesLabel`]),
          applyActionText: t([`${filterPrefix}.applyActionText`, `propertyFilter.applyActionText`]),
          cancelActionText: t([`${filterPrefix}.cancelActionText`, `propertyFilter.cancelActionText`]),
          clearAriaLabel: t([`${filterPrefix}.clearAriaLabel`, `propertyFilter.clearAriaLabel`]),
          clearFiltersText: t([`${filterPrefix}.clearFiltersText`, `propertyFilter.clearFiltersText`]),
          dismissAriaLabel: t([`${filterPrefix}.dismissAriaLabel`, `propertyFilter.dismissAriaLabel`]),
          editTokenHeader: t([`${filterPrefix}.editTokenHeader`, `propertyFilter.editTokenHeader`]),
          filteringAriaLabel: t([`${filterPrefix}.filteringAriaLabel`, `propertyFilter.filteringAriaLabel`]),
          filteringPlaceholder: t([`${filterPrefix}.filteringPlaceholder`, `propertyFilter.filteringPlaceholder`]),
          groupPropertiesText: t([`${filterPrefix}.groupPropertiesText`, `propertyFilter.groupPropertiesText`]),
          groupValuesText: t([`${filterPrefix}.groupValuesText`, `propertyFilter.groupValuesText`]),
          operationAndText: t([`${filterPrefix}.operationAndText`, `propertyFilter.operationAndText`]),
          operationOrText: t([`${filterPrefix}.operationOrText`, `propertyFilter.operationOrText`]),
          operatorContainsText: t([`${filterPrefix}.operatorContainsText`, `propertyFilter.operatorContainsText`]),
          operatorDoesNotContainText: t([`${filterPrefix}.operatorDoesNotContainText`, `propertyFilter.operatorDoesNotContainText`]),
          operatorDoesNotEqualText: t([`${filterPrefix}.operatorDoesNotEqualText`, `propertyFilter.operatorDoesNotEqualText`]),
          operatorDoesNotStartWithText: t([`${filterPrefix}.operatorDoesNotStartWithText`, `propertyFilter.operatorDoesNotStartWithText`]),
          operatorEqualsText: t([`${filterPrefix}.operatorEqualsText`, `propertyFilter.operatorEqualsText`]),
          operatorGreaterOrEqualText: t([`${filterPrefix}.operatorGreaterOrEqualText`, `propertyFilter.operatorGreaterOrEqualText`]),
          operatorGreaterText: t([`${filterPrefix}.operatorGreaterText`, `propertyFilter.operatorGreaterText`]),
          operatorLessOrEqualText: t([`${filterPrefix}.operatorLessOrEqualText`, `propertyFilter.operatorLessOrEqualText`]),
          operatorLessText: t([`${filterPrefix}.operatorLessText`, `propertyFilter.operatorLessText`]),
          operatorStartsWithText: t([`${filterPrefix}.operatorStartsWithText`, `propertyFilter.operatorStartsWithText`]),
          operatorText: t([`${filterPrefix}.operatorText`, `propertyFilter.operatorText`]),
          operatorsText: t([`${filterPrefix}.operatorsText`, `propertyFilter.operatorsText`]),
          propertyText: t([`${filterPrefix}.propertyText`, `propertyFilter.propertyText`]),
          removeTokenButtonAriaLabel: (token) => t([`${filterPrefix}.removeTokenButtonAriaLabel`, `propertyFilter.removeTokenButtonAriaLabel`], { token: token.value }),
          tokenLimitShowFewer: t([`${filterPrefix}.tokenLimitShowFewer`, `propertyFilter.tokenLimitShowFewer`]),
          tokenLimitShowMore: t([`${filterPrefix}.tokenLimitShowMore`, `propertyFilter.tokenLimitShowMore`]),
          tokenOperatorAriaLabel: t([`${filterPrefix}.tokenOperatorAriaLabel`, `propertyFilter.tokenOperatorAriaLabel`]),
          valueText: t([`${filterPrefix}.valueText`, `propertyFilter.valueText`])
        }
}