import { locationOptions } from "../data/locations"

export interface Coordinate {
    latitude: number;
    longitude: number;
}

export function getUserLocation(getClosestCity: PositionCallback) {
  if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(getClosestCity);
  } else {
      console.log("Geolocation is not supported by this browser.")
    }
  }

export const getClosestCity = (curPos: GeolocationPosition) => {
  let closestCityCoords = locationOptions[0].value
  let minDist = 100000000

  for (const city of locationOptions) {
    const dist = calcDistance(curPos.coords, parseCoords(city.value))
    if (dist < minDist) {
        closestCityCoords = city.value 
        minDist = dist
    }
  }
  return closestCityCoords
}

function parseCoords(str: string) {
    const coords = str.split(',').map(coord => Number.parseFloat(coord))
    return {
        latitude: coords[0],
        longitude: coords[1]
    } as Coordinate
}

// reference: https://enjeck.com/blog/distance-two-locations/
function calcDistance (startingCoords: Coordinate, destinationCoords: Coordinate) {
    const startingLat = degreesToRadians(startingCoords.latitude);
    const startingLong = degreesToRadians(startingCoords.longitude);
    const destinationLat = degreesToRadians(destinationCoords.latitude);
    const destinationLong = degreesToRadians(destinationCoords.longitude);
  
    // Radius of the Earth in kilometers
    const radius = 6571;
  
    // Haversine equation
    const distanceInKilometers = Math.acos(Math.sin(startingLat) * Math.sin(destinationLat) +
    Math.cos(startingLat) * Math.cos(destinationLat) *
    Math.cos(startingLong - destinationLong)) * radius;
    return distanceInKilometers;
}

  // Convert from degrees to radians
function degreesToRadians(degrees: number) {
    return (degrees * Math.PI)/180;
}
