import { useTranslation } from "react-i18next";
import { WizardProps } from "@amzn/awsui-components-react/polaris/wizard";

// These functions provide a default pattern for i18n strings from Cloudscape components
// Default values are stored in src/localization/{lng}.json, but can be overriden by specific prefixes or namespaces
// ONLY override the SPECIFIC strings that need to change, and leave the strings where you wish to use the defaults as undefined in the prefix

export function useWizardStrings(wizardPrefix: string, namespaces: string[] = ['translation']): WizardProps.I18nStrings {
  const { t } = useTranslation(namespaces);

  return {
      stepNumberLabel: stepNumber => t([`${wizardPrefix}.stepNumberLabel`, `wizard.stepNumberLabel`], { stepNumber }),
      collapsedStepsLabel: (stepNumber, stepsCount) => t([`${wizardPrefix}.collapsedStepsLabel`, `wizard.collapsedStepsLabel`], { stepNumber, stepsCount }),
      skipToButtonLabel: (step, stepNumber) => t([`${wizardPrefix}.skipToButtonLabel`, `wizard.skipToButtonLabel`], { title: step.title }),
      navigationAriaLabel: t([`${wizardPrefix}.navigationAriaLabel`, `wizard.navigationAriaLabel`]),
      cancelButton: t([`${wizardPrefix}.cancelButton`, `wizard.cancelButton`]),
      previousButton: t([`${wizardPrefix}.previousButton`, `wizard.previousButton`]),
      nextButton: t([`${wizardPrefix}.nextButton`, `wizard.nextButton`]),
      submitButton: t([`${wizardPrefix}.submitButton`, `wizard.submitButton`]),
      optional: t([`${wizardPrefix}.optional`, `wizard.optional`])
  }
}