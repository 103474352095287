import React from "react";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import Header from "@amzn/awsui-components-react/polaris/header";
import Button from "@amzn/awsui-components-react/polaris/button";
import Icon from "@amzn/awsui-components-react/polaris/icon";
import TextContent from "@amzn/awsui-components-react/polaris/text-content";
import { useTranslation } from "react-i18next";

import { externalLinks } from "../data/externalLinks";

function WayButton({ type }: { type: "planEvent" | "plugAndPlay" | "assistedPlanning" }) {
  const { t } = useTranslation();

  return (
    <Button       
      href={externalLinks.moreWays[type]}
      target="_blank"
      rel="noreferrer"
      data-external-analytics-on="click"
      data-external-analytics-name={type}
      data-aci-analytics-name={type}>
      <div className="boxbutton">
        <div className="text-content">
          <b>{t(`moreWays.${type}.title`)}</b>
          <br/>
          <span>{t(`moreWays.${type}.copy`)}</span>
        </div>
        <Icon name="external"/>
      </div>
    </Button>
  );
}

export function MoreWaysFooter({dark}:{dark?:boolean}) {
  const { t } = useTranslation();

  return (
    <div className={dark ? "more-ways-dark" : "more-ways"}>
        <TextContent>
          <Header variant="h2">{t("moreWays.title")}</Header>
          <p>{t("moreWays.copy")}</p>
          
          <Grid gridDefinition={[
              { colspan: { default: 12, xl: 4, l: 4,  m: 4, s: 4, xs: 4 } },
              { colspan: { default: 12, xl: 4, l: 4,  m: 4, s: 4, xs: 4 } },
              { colspan: { default: 12, xl: 4, l: 4, m: 4, s: 4, xs: 4 } }]}>
            <WayButton type="planEvent"/>
          </Grid>
        </TextContent>
      </div>
  );
}
