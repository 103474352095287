import { getJson } from './request';
import {getIntFromUrlParams, toQueryString} from '../utils/url';

export const DEFAULT_EVENTS_PAGE_SIZE = 25;
export const DEFAULT_EVENTS_PAGE = 1;
export const DEFAULT_QUERIES_FOR_ALL: string [] = []; // ex: ["#GreatforTeams"]

const getQueryString = (params: URLSearchParams) => {
  const size = getIntFromUrlParams(params, "pageSize", DEFAULT_EVENTS_PAGE_SIZE);
  const start = getIntFromUrlParams(params, "page", DEFAULT_EVENTS_PAGE);

  const endsAfter = params.get("start_timestamp");
  const startsBefore = params.get("end_timestamp");

  const queryStringQueries:string[] = []
  const query = params.get("query");
  if (query != null) {
    queryStringQueries.push(query);
  }
  const allQueries = queryStringQueries.concat(DEFAULT_QUERIES_FOR_ALL)

  const hasLocation = params.get("activity_location") === "in-person";
  const skills = decodeURIComponent(params.get("skills") || "");
  const interests = decodeURIComponent(params.get("interests") || "");

  return toQueryString({
    q: allQueries.join(" "),
    minimum_open_spots: params.get("spots") || "1",
    search_location: params.get("volunteer_location"),
    ends_after: endsAfter ? endsAfter + "T00:00:00+00:00" : null,
    starts_before: startsBefore ? startsBefore + "T23:59:59+00:00" : null,
    has_location: !params.get("activity_location") || params.get("activity_location") === "all" ? null : hasLocation,
    page: start,
    page_size: size,
    autocorrect: true,
    visibility: "program",
    interests: interests && interests !== "[]" ? encodeURIComponent(interests) : null,
    skills: skills && skills !== "[]" ? encodeURIComponent(skills) : null,
    temporal_type: params.get("frequency") !== "all" ? params.get("frequency") : null
  });
}

export const getEvents = (params: URLSearchParams) => getJson(`/volunteeropportunities${getQueryString(params)}`);

export const getEvent = (eventId: string) => {
  return getJson(
    `/volunteeropportunity/${eventId}`,
  );
};
