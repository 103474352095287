import React from "react";
import Button, { ButtonProps } from "@amzn/awsui-components-react/polaris/button";
import Link, { LinkProps } from "@amzn/awsui-components-react/polaris/link";
import { NavigateFunction, useNavigate } from "react-router-dom";


type Callback = (event: CustomEvent) => void;
//this will infer the right link from the href on the source link
//but if that is not set for whatever reason you can override it.
export function useSpaWrapper() {
  const navigate = useNavigate();
  return (opts: { url?: string, onFollow?: Callback }) => wrapSpaOnFollow(Object.assign({}, { navigate }, opts))
}

export function wrapSpaOnFollow(opts: { navigate: NavigateFunction, onFollow?: Callback, url?: string }): Callback {
  return (event: CustomEvent) => {
    if (opts.onFollow) {
      opts.onFollow(event);
    }
    event.preventDefault();
    opts.navigate(opts.url ?? event.detail.href);
  }
}
//https://github.com/cloudscape-design/components/discussions/1699
/**
 * In order to support the polaris link AND correctly integrate with 
 * router navigation (so as to not reload the app every time you click a link),
 * you need to specify href AND onFollow.
 * @param props 
 * @returns 
 */
export function SpaLink(props: LinkProps) {
  const spaWrapper = useSpaWrapper();

  const propsWithOnFollow = Object.assign({}, props, {
    onFollow: spaWrapper({ onFollow: props.onFollow, url: props.href })
  });
  return <Link {...propsWithOnFollow} />
}

export function SpaButton(props: ButtonProps) {
  const spaWrapper = useSpaWrapper();

  const propsWithOnFollow = Object.assign({}, props, {
    onFollow: spaWrapper({ onFollow: props.onFollow, url: props.href })
  });
  return <Button {...propsWithOnFollow} />
}