/* istanbul ignore file */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import { Amplify } from 'aws-amplify';
import { getAuthConfig } from './utils/requestConfig';
import { AppRoutes } from './routes/appRoutes';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';

const callbackUrl = window.location.origin;
const authConfig = getAuthConfig()
const amplifyConfig = {
  Auth: {
    identityPoolId: authConfig.identityPoolId,
    region: authConfig.region,
    userPoolId: authConfig.userPoolId,
    userPoolWebClientId: authConfig.userPoolClientId,
    mandatorySignIn: false,
    signUpVerificationMethod: 'code',
    oauth: {
      domain: authConfig.domain,
      scope: [
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin'
      ],
      redirectSignIn: callbackUrl,
      redirectSignOut: callbackUrl,
      responseType: 'code'
    }
  }
};
Amplify.configure(amplifyConfig)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Query client queries are set to never go stale so that we always
// pull in any cached data.  We then manually reset the queries on 
// user actions (such as navigating to a new page)
// This ensures that we can use the same query in subcomponents as
// a decomposed state, without falling into the accessibility pitfalls
// of dynamically changing the app based on new query content
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  }
});

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <AppRoutes />
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);
