import { useSearchParams } from "react-router-dom";
import { getDateRangeFromSearchParams, getValueFromSearchParam, getOptionFromLabel } from "../utils/url";
import { useCallback, useEffect, useMemo, useState } from "react";
import { publishMetricsWithAttributes } from "../components/analytics";
import { publishKatalMetric } from "../components/katalAnalytics";
import debounce from "lodash.debounce";
import { MultiselectProps } from "@amzn/awsui-components-react/polaris/multiselect/interfaces";
import { getClosestCity, getUserLocation } from "../utils/geolocationHelper"

interface FilterUpdaters {
  setQuery: (s:string)=>void,
  setSpots: (s:string)=>void,
  setStartTime: (s:string)=>void,
  setArea: (s:string)=>void,
  setEndTime: (s:string)=>void
}

// Note: end_timestamp is not included in this list as it would double count the Date Range filter
const filterNames = ["query", "volunteer_location", "activity_location", "area", "frequency", "start_time", "end_time",
  "days_of_week", "spots", "opportunity_type", "start_timestamp", "activity_length"];

function getActiveFilterCount(searchParams: URLSearchParams, filterNames: string[]) {
  let numFilters = 0;

  for(const filter in filterNames) {
    if (getValueFromSearchParam(searchParams, filterNames[filter]) !== "") {
      numFilters++;
    }
  }

  return numFilters
}

export function useSharedFilters() {
  const [searchParams, setSearchParams] = useSearchParams();

  let closestCityCoords;
  getUserLocation((curPos: GeolocationPosition) => {
    closestCityCoords = getClosestCity(curPos)
    return closestCityCoords;
  })
  const query = getValueFromSearchParam(searchParams, "query");
  const area = getValueFromSearchParam(searchParams, "volunteer_location", closestCityCoords);
  const start_time = getValueFromSearchParam(searchParams, "start_time");
  const end_time = getValueFromSearchParam(searchParams, "end_time");
  const daysOfWeek = getValueFromSearchParam(searchParams, "days_of_week", "[]");
  const spots = getValueFromSearchParam(searchParams, "spots");
  const dateRange = getDateRangeFromSearchParams(searchParams);

  const [queryInput, setQuery] = useState(query);
  const [areaInput, setArea] = useState(area);
  const [startTimeInput, setStartTime] = useState(start_time);
  const [endTimeInput, setEndTime] = useState(end_time);
  const [spotsInput, setSpots] = useState(spots);

  const [activeFilterCount, setActiveFilterCount] = useState(
    getActiveFilterCount(searchParams, filterNames));

  const filters = {
    query: queryInput,
    activityLocation: getValueFromSearchParam(searchParams, "activity_location"),
    frequency: getValueFromSearchParam(searchParams, "frequency"),
    startTime: startTimeInput,
    endTime: endTimeInput,
    area: areaInput,
    daysOfWeek,
    spots: spotsInput,
    dateRange,
    activityLength: getValueFromSearchParam(searchParams, "activity_length"),
    activeFilterCount
  }

  const updaters: FilterUpdaters = {
    setQuery,
    setArea,
    setStartTime,
    setEndTime,
    setSpots
  }

  const updateFilter = useCallback(
    (paramName: string, value: string, isHighlighted: boolean) => {
      setSearchParams(params => {
        if(paramName !== "page" && params.has("page")) {
          params.delete("page");
        }
        if(value == null || value === "") {
          params.delete(paramName);
        } else {
          params.set(paramName, value);
        }
        return params
      });
      const filterType : string = isHighlighted ? "changeHighlightedFilter" : "changeFilter";
      publishMetricsWithAttributes(filterType, paramName, value);
      publishKatalMetric(filterType + "_" + paramName, value);
    }, [setSearchParams]
  );

  // Ensures we are only changing the filter when the input pauses
  const debounceUrlUpdate = useMemo(
    () => debounce((key: string, value: string, isHighlighted) => updateFilter(key, value, isHighlighted), 300), [updateFilter]
  );

  function setValueWithDebounce(name: string, value: string, setter: (s:string)=>void, isHighlighted = false){
    setter(value);
    debounceUrlUpdate(name, value, isHighlighted);
  }

  function setValueWithoutDebounce(name: string, value: string, isHighlighted = false){
    updateFilter(name, value, isHighlighted);
  }

  function setAutocompleteValueWithDebounce(name: string, value: string, matcher: RegExp, options: readonly MultiselectProps.Option[], setter: (s:string)=>void, isHighlighted = false){
    setter(value);
    if(!!value.match(matcher)) debounceUrlUpdate(name, value, isHighlighted);
    const option = getOptionFromLabel(value, options)
    if(!!option) updateFilter(name, option.value || "", isHighlighted);
  }

  function setMultiValueWithoutDebounce(name: string, values: readonly MultiselectProps.Option[], isHighlighted = false) {
    updateFilter(name, `[${values.map(selectedVal => selectedVal.value).join(',')}]`, isHighlighted)
  }

  function resetFilters() {
    setSearchParams(params => {
      filterNames.forEach(filterName => params.delete(filterName));
      // This is not listed in the filterNames list as it is set simultaneously with start_timestamp and counts
      // as one filter, but we still need to clear it
      params.delete("end_timestamp");
      // we must clear the page if there is already one since changing the filters means we are starting over.
      params.delete("page");
      return params
    });
  }

  // This ensures that any changes made to a debounced shared filter in one area will percolate to any form fields
  // using the same shared filter in other locations
  // This is only necessary for debounced filters because they do not update URL params on every user event
  useEffect(() => {
    setQuery(query);
  }, [query]);

  useEffect(() => {
    setStartTime(start_time);
  }, [start_time]);

  useEffect(() => {
    setEndTime(end_time);
  }, [end_time]);

  useEffect(() => {
    setSpots(spots);
  }, [spots]);

  useEffect(() => {
    setActiveFilterCount(getActiveFilterCount(searchParams, filterNames));
  }, [searchParams,
    query,
    filters.activityLocation,
    filters.area,
    filters.frequency,
    daysOfWeek,
    dateRange,
    filters.activityLength,
    start_time,
    end_time,
    spots
  ]);

  return {
    filters,
    updaters,
    setValueWithDebounce,
    setValueWithoutDebounce,
    setAutocompleteValueWithDebounce,
    setMultiValueWithoutDebounce,
    resetFilters,
    updateFilter,
  }
}