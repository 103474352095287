// GOLDEN PATH CODE
// This is an example of how to call a Smithy client with next-page type pagination
// Ie. the client does not provide tokens for previous pages, and tokens cannot be logically generated by
// knowing the page number
import { useState } from "react";
import { useQuery, useSuspenseQuery, useQueryClient, QueryClient } from "@tanstack/react-query";
import { 
  RedVelvetApiMethod,
  RedVelvetApiMethodParameters,
  RedVelvetApiMethodOutput,
  callClientByMethodName 
  } from "../api/redVelvetQueries";

export type UsePaginatedRedVelvetQueryOptions<K extends RedVelvetApiMethod>=  {
  enabled?:boolean,
  suspense?: boolean,
  redVelvetQueryKey:[K, RedVelvetApiMethodParameters<K>] // Forces query key to be [methodName, methodParameters]
}

async function callRedVelvetClient<K extends RedVelvetApiMethod>(queryClient: QueryClient, options:UsePaginatedRedVelvetQueryOptions<K>, pageIndex:number, prefetch=false){
  const [methodName, methodParameters] = options.redVelvetQueryKey;
  // GOLDEN PATH NOTE
  // If we are not navigating to the first page, we first have to find the page token for the proper page
  // However, since most users paginate by going to the next page, previous page, or first page,
  // we can leverage the caching functionality of react-query to quickly get page tokens for the next and previous pages
  // by recursively calling the same fetch for previous pages.
  let page:string | undefined = undefined;
  if(pageIndex !== 1) {
    const q = await queryClient.fetchQuery({
      // GOLDEN PATH NOTE
      // Here, and everywhere else where we set the queryKey, we prefix it with the API
      // that we are calling, so that multiple APIs with the same method won't cause cache collisions
      queryKey:["RedVelvetApi", ...options.redVelvetQueryKey, pageIndex - 1],
      queryFn: () => callRedVelvetClient(queryClient, options, pageIndex - 1)
    });

    if(q?.page === undefined) {
      return undefined;
    }
    page = q.page;
  }


  // GOLDEN PATH NOTE
  // If we are on the first page, we simply call the query to get the first page of values, including the page token for
  // the next page
  const result: RedVelvetApiMethodOutput<K> = await callClientByMethodName(methodName, { ...methodParameters, page });

  // GOLDEN PATH NOTE
  // We start the query for the next page here, to help reduce load times for the user when going to the 
  // next page.  As a note, prefetch (and fetch) will de-dupe any in-flight queries, so this will not launch
  // extra queries if the user tries to visit the next page before the prefetch resolves
  // See https://tanstack.com/query/latest/docs/reference/QueryClient/#queryclientprefetchquery for more details
  // We also wrap it in a conditional that checks if the current query is already a pre-fetch, so that 
  // we do not continuously pre-fetch all pages, one after the other
  if(!prefetch && result.page) {
    queryClient.prefetchQuery({
      queryKey:["RedVelvetApi", ...options.redVelvetQueryKey, pageIndex + 1],
      queryFn:()=>callRedVelvetClient(queryClient, options, pageIndex + 1, true)
    });
  }
  return result;
}

function useLimitedPagination() {
  // GOLDEN PATH NOTE
  // Pagination always starts at page 1, for compatibility with Cloudscape
  const [pageIndex, setPageIndex] = useState<number>(1);
  
  return {
    pageIndex, 
    setPageIndex: (value:number)=>{
      if(value < 1) {
        return;
      }
      setPageIndex(value);
    }
  }
}

function usePaginatedPresets<K extends RedVelvetApiMethod>(options:UsePaginatedRedVelvetQueryOptions<K>, pageIndex: number) {
  const queryClient = useQueryClient();
  return {
    queryOpts: {
      queryKey:["RedVelvetApi", ...options.redVelvetQueryKey, pageIndex],
      queryFn:() => callRedVelvetClient(queryClient, options, pageIndex)
    },
    refresh: ()=>{
      queryClient.resetQueries();
    }
  }
}

export function usePaginatedRedVelvetQuery<K extends RedVelvetApiMethod>(options:UsePaginatedRedVelvetQueryOptions<K>, page: number | undefined = undefined) {
  const { pageIndex, setPageIndex } = useLimitedPagination();
  const { queryOpts, refresh } = usePaginatedPresets(options, page || pageIndex);

  return {
    queryResult: useQuery({ ...queryOpts, enabled: options.enabled }),
    pageIndex: page || pageIndex, 
    setPageIndex,
    refresh
  };
}

export function useSuspensePaginatedRedVelvetQuery<K extends RedVelvetApiMethod>(options:UsePaginatedRedVelvetQueryOptions<K>, page: number | undefined = undefined) {
  const { pageIndex, setPageIndex } = useLimitedPagination();
  const { queryOpts, refresh } = usePaginatedPresets(options, page || pageIndex);

  return {
    queryResult: useSuspenseQuery(queryOpts),
    pageIndex: page || pageIndex, 
    setPageIndex,
    refresh
  };
}
