import { ReactElement } from "react";
import { useFeatures } from "../hooks/features";
import { useUser } from "../hooks/user";

/**
 * render the element returned by the loading parameter until features are available, at which point render 
 * the content of the page. 
 */
export function GateOnFeatureLoad({ loading, render }: { loading: () => ReactElement, render: () => ReactElement }) {
  const features = useFeatures();
  if (features !== null) {
    return render();
  }
    
  return loading();
}

/**
 * render only the loading element until the user is authenticated and then render the render element.
 * 
 * If user does not have permission to view the site, render the Access Denied page
 */
export function GateOnUserLoad({ loading, error, unauthorized, authorized }: { loading: () => ReactElement, error: () => ReactElement, unauthorized: () => ReactElement, authorized: () => ReactElement }) {
  const loggedInUser = useUser();
  
  if(loggedInUser.isError) return error();
  
  if(loggedInUser.isPending) return loading();
  
  
  if(loggedInUser.permissions && loggedInUser.permissions.includes("canView")) {
    return authorized();
  }
    
  return unauthorized();
}
