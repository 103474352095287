import { useTranslation } from "react-i18next";
import { EventSummary } from "../api/schemas/eventSummary";
import { Shift } from "../api/schemas/shift";
import { externalLinks } from "../data/externalLinks";
import { getShiftLengthLabel } from "./eventDisplay";
import Header from "@amzn/awsui-components-react/polaris/header";

export const dateDisplayOptions: Intl.DateTimeFormatOptions = {
  month: "long",
  day: "numeric",
  year: "numeric"
};

export const timeDisplayOptions: Intl.DateTimeFormatOptions = {
  timeZoneName: "shortGeneric",
  hour: "numeric",
  minute: "numeric"
}

export function ShiftDisplay({ event, shift, selectedTimezone }: {event: EventSummary, shift: Shift, selectedTimezone: string}) {
  const { t, i18n } = useTranslation();

  const hasLimitedSpots = shift.signups_remaining && shift.signups_max;
  const limitedSpots = shift.signups_remaining + "/" + shift.signups_max;

  const startDate = new Date(shift.start_timestamp || "");
  const endDate = new Date(shift.end_timestamp || "");
  const diffTime = endDate.getTime() - startDate.getTime();

  const dateString = startDate.toLocaleString(i18n.language,
      {...timeDisplayOptions, timeZone: selectedTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone});
  const localeStartDateString = startDate.toLocaleDateString(i18n.language, {...dateDisplayOptions, timeZone:selectedTimezone});

  const dateTimeShiftDisplay = shift.start_timestamp  ?
    <div>
      <p><b>{t("eventDetails.shiftDisplay.date")}: </b>{localeStartDateString}</p>
      <p><b>{t("eventDetails.shiftDisplay.startTime")}: </b>{dateString}</p>
      <p><b>{t("eventDetails.shiftDisplay.length")}: </b>{getShiftLengthLabel(t, diffTime)}</p>
    </div> :
    <p><b>{t("eventDetails.shiftDisplay.date")}: </b>{t("eventDisplay.ongoingEvent")}</p> ;


  return (<div className="shift">
    <Header variant="h3">
      {shift.shift_name}
    </Header>
    <div className="shiftBody">
      {dateTimeShiftDisplay}
      <p><b>{t("eventDetails.shiftDisplay.availableSpots")}: </b>{hasLimitedSpots ? limitedSpots : t("eventDetails.shiftDisplay.unlimited")}</p>
    </div>
    <a className="registerLink"
      href={externalLinks.benevity.signup + `${event.id}?utm_source=${externalLinks.utm.source}&utm_medium=${'EventPage'}&utm_campaign=${externalLinks.utm.campaign}`}
      data-external-analytics-on="click"
      data-external-analytics-name="signupInBenevityDetails"
      data-external-analytics-attrs={`eventId:${event.id}`}
      data-aci-analytics-name={`signupInBenevityDetails`}
      target="_blank"
      rel="noreferrer">
      {t("eventDetails.shiftDisplay.signUp")}
    </a>
  </div>);
}
